<template>
    <div>
        <div
            key="detail-content"
            class="detail-content"
            :style="{ 'margin-bottom': tabHeight + 'px' }"
        >
            <slot name="detailContent" :undoChat="undoChat" />
        </div>
        <div
            v-if="hasNetworkConnection"
            key="send-button"
            :class="['footer-actions', `${navBarPosition}-nav`]"
            :style="{ bottom: tabHeight + 'px' }"
        >
            <Typography
                v-if="modalSource === feedbackDetail"
                variant="body2"
                class="reply-text"
                @click="openMessageComposer()"
            >
                Add a reply...
            </Typography>
            <PillButton
                v-else
                label="New Message"
                class="message-button"
                @click="openMessageComposer()"
            />
        </div>
        <Portal v-if="showPopup" key="portal" to="over-card-destination">
            <FeedbackMessageModal
                v-if="modalSource === feedbackDetail"
                :close-popup="() => closeMessageComposer()"
                :modal-source="modalSource"
                :feedbackid="feedbackid"
                :momentid="momentid"
                :init-as-reply="forRestore"
                :init-with-text="forRestore ? textForRestore : ''"
            />
            <MomentModal
                v-else
                :close-popup="() => closeMessageComposer()"
                :modal-source="modalSource"
                :feedbackid="feedbackid"
                :momentid="momentid"
            />
        </Portal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { namespace } from 'vuex-facing-decorator'
import PillButton from '@/components/PillButton.vue'
import MomentModal from '@/mobile/src/components/appV4/MomentModal.vue'
import FeedbackMessageModal from '@/mobile/src/components/appV4/feedback/FeedbackMessageModal.vue'
import { ModalSource } from '@/mobile/src/types/messageTypes'
import { Portal } from 'portal-vue'
import { pendoTrackEvent } from '@/utils/pendo'
import Typography from '@/components/Rain/Typography/Typography.vue'

const MobileNavigationModule = namespace('mobilenavigation')
const MomentsModule = namespace('moments')

@Component({
    components: {
        MomentModal,
        FeedbackMessageModal,
        PillButton,
        Portal,
        Typography,
    },
    emits: ['message-sent'],
})
export default class ItemDetail extends Vue {
    @Prop({ type: String, default: null }) feedbackid?: string
    @Prop({ type: String, default: null }) momentid?: string
    @MobileNavigationModule.Getter tabHeight
    @MomentsModule.Getter getComposerSource!: string

    @MomentsModule.Action setTypePickerModalOpen

    @Getter readonly hasNetworkConnection
    @Getter navBarPosition!: 'horizontal' | 'vertical'

    public showPopup = false
    public forRestore = false
    public textForRestore = ''
    public feedbackDetail = ModalSource.FeedbackDetailScreen

    public openMessageComposer() {
        this.showPopup = true
        this.forRestore = false
        pendoTrackEvent(`message_composer_initialised`, {
            source: this.modalSource ?? 'unknown',
        })
    }

    public closeMessageComposer() {
        this.showPopup = false
        this.forRestore = false
        this.setTypePickerModalOpen(false)
        this.$emit('message-sent')
    }

    public get modalSource() {
        if (this.momentid) {
            return ModalSource.MomentsDetailScreen
        }
        if (this.feedbackid) {
            return ModalSource.FeedbackDetailScreen
        }
        return ''
    }

    public undoChat(data) {
        this.forRestore = true
        this.textForRestore = data.message
        this.showPopup = true
        pendoTrackEvent(`message_composer_undone`, {
            source: this.modalSource ?? 'unknown',
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';
@import '~@/styles/palette.less';

.pill-button {
    line-height: @fontSize-md;
    color: @grey90;
    width: 130px;
}

.footer-actions {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    position: fixed;
    left: 0;
    height: @mobileNavHeight;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.vertical-nav {
        width: calc(100% - @mobileNavHeight);
        right: 0;
        left: unset;
    }

    .reply-text {
        color: @white;
        padding-left: 20px;
        width: 100%;
    }

    .message-button {
        margin: 0 auto;
    }
}

.back {
    color: @white;
    flex: 1;
}

.action {
    color: @white;
    flex: 1;
    text-align: right;
}
</style>
