import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/right-arrow.svg'


const _withScopeId = n => (_pushScopeId("data-v-475ea293"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "breadcrumbs-arrow",
  src: _imports_0,
  alt: "right arrow"
}
const _hoisted_4 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([
                'vtabs',
                { vsmall: _ctx.vsmall, small: _ctx.small, large: _ctx.large, pills: _ctx.pills },
                { 'vtab-full-width': _ctx.fullWidth },
            ])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, tabIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tabIndex,
          class: _normalizeClass({
                    vtab: true,
                    active: tab.name === _ctx.active,
                    disabled: tab.disabled,
                    vsmall: _ctx.vsmall,
                    small: _ctx.small,
                    large: _ctx.large,
                    pills: _ctx.pills,
                    invalid: _ctx.errorIndices.indexOf(tabIndex) > -1,
                    showArrow: _ctx.showArrow,
                    breadcrumbs: _ctx.breadcrumbs,
                }),
          onClick: _withModifiers(($event: any) => (!tab.disabled && _ctx.selectTab(tab.name)), ["self"])
        }, [
          _createTextVNode(_toDisplayString(tab.displayName ? tab.displayName : tab.name) + " ", 1),
          (_ctx.showRemoveTab)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['vtab-remove', { inactive: tab.name !== _ctx.active }]),
                onClick: ($event: any) => (_ctx.removeTab(tab.name))
              }, " × ", 10, _hoisted_2))
            : _createCommentVNode("", true),
          (
                        _ctx.breadcrumbs && _ctx.showArrow && tabIndex < _ctx.tabs.length - 1
                    )
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
      ])
    ], 2),
    _createElementVNode("div", {
      ref: "tabContainer",
      class: _normalizeClass([
                'vtabs-content',
                { vsmall: _ctx.vsmall, small: _ctx.small, large: _ctx.large, pills: _ctx.pills, breadcrumbs: _ctx.breadcrumbs },
            ])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}