<template>
    <div id="notifications-panel" :class="[`${navBarPosition}-nav`]">
        <div id="notifications-header">
            <Typography class="header-text" variant="sub1">Notifications</Typography>
            <button
                v-if="notifications.length"
                id="mark-as-read-button"
                @click="markAllAsRead"
            >
                <Typography class="header-text" variant="overline">
                    Mark all as read
                </Typography>
            </button>
        </div>
        <LoadingCard
            v-if="notificationsLoading && showLoadingAnimation"
            borderless
        />
        <div
            v-else-if="notifications.length > 0"
            id="notifications-list"
            @scroll="onListScroll"
        >
            <div
                v-for="notification in notifications"
                :key="notification.id"
                class="notification"
                @click="onNotificationClick(notification)"
            >
                <div
                    :class="[
                        'notification-avatar',
                        { read: notification.read },
                    ]"
                >
                    <div v-if="!notification.read" class="unread-dot" />
                    <AvatarOrInitials
                        v-if="hasSender(notification)"
                        :user="getSender(notification)"
                        :size="38"
                    />
                    <img
                        v-else
                        class="an-purple-logo"
                        :src="ANPurpleLogo"
                        alt="AskNicely Logo"
                    />
                </div>
                <div class="notification-text">
                    <Typography variant="overline" class="title">
                        {{ parseTitle(notification.type) }}
                    </Typography>
                    <Typography variant="body2" class="content">
                        <TextClamp
                            autoresize
                            :max-lines="2"
                            :text="getMessage(notification)"
                        />
                    </Typography>

                    <div class="footer">
                        <Typography variant="overline" class="footer-text">
                            {{ getSender(notification).name }}
                        </Typography>
                        <Typography variant="overline" class="footer-text">
                            {{ getTimeSince(notification.created) }}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
        <div v-else id="empty-notifications">
            <EmptyPageState colour="white">
                <template #icon-img><FontAwesomeIcon
                        :icon="faInbox"
                        class="inbox-icon"
                        color="white"
                /></template>
                <template #header-text>You currently have no notifications</template>
                <template #body-text>
                    Check back here for updates about customer responses
                </template>
            </EmptyPageState>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Getter, namespace } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faInbox } from 'fontawesome/free-solid-svg-icons'
import { timeSince } from '@/utils/time'
import { pendoTrackEvent } from '@/utils/pendo'
import LogoCircle from '@/assets/img/logo/asknicely-logo-circle-purple.svg'
import Typography from '@/components/Rain/Typography/Typography.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { InAppNotification } from '@/mobile/src/types/notifications'
import { parseMentionsAndTags } from '@/utils/string'
import TextClamp from '@/components/TextClamp.vue'

const NotificationsModule = namespace('notifications')
const ReportCardModule = namespace('reportcard')

@Component({
    components: {
        TextClamp,
        LoadingCard,
        EmptyPageState,
        AvatarOrInitials,
        Typography,
        FontAwesomeIcon,
    },
})
export default class NotificationsPanel extends Vue {
    @NotificationsModule.Action loadNextPageOfNotifications
    @NotificationsModule.Action markAllNotificationsAsRead
    @NotificationsModule.Action markNotificationAsRead
    @NotificationsModule.Getter notifications!: InAppNotification[]
    @NotificationsModule.Getter notificationsLoading!: boolean
    @NotificationsModule.Getter canLoadMore!: boolean

    @ReportCardModule.Action loadReportCardData
    @ReportCardModule.Action setShowReportCard

    @Getter navBarPosition!: 'horizontal' | 'vertical'

    public faInbox = faInbox
    public ANPurpleLogo = LogoCircle

    public showLoadingAnimation = false

    private router = useRouter()

    public async created() {
        this.showLoadingAnimation = true
        await this.paginateNotifications()
        this.showLoadingAnimation = false
    }

    public async onListScroll(event) {
        if (!this.notificationsLoading && this.canLoadMore) {
            // Trigger the next load when the user scrolls almost to the bottom, hence why we are adding a buffer
            const buffer = 350
            const positionToTriggerNextLoad =
                event.target.scrollTop + event.target.offsetHeight + buffer

            if (positionToTriggerNextLoad > event.target.scrollHeight) {
                await this.paginateNotifications()
                pendoTrackEvent('mobile-notifications-new-page-loaded')
            }
        }
    }

    public async paginateNotifications() {
        if (this.canLoadMore) {
            await this.loadNextPageOfNotifications({ initialLoad: true })
        }
    }

    public getTimeSince(timestamp: number): string {
        return timeSince(timestamp)
    }

    public markAllAsRead(): void {
        this.markAllNotificationsAsRead()
    }

    public parseTitle(title: string) {
        return title.replaceAll('_', ' ')
    }

    public hasSender(notification: InAppNotification) {
        return notification.senderName && notification.senderId
    }

    public getMessage(notification: InAppNotification): string {
        return parseMentionsAndTags(notification.text ?? notification.message)
    }

    public getSender(notification: InAppNotification) {
        const name = notification.senderName ?? 'AskNicely'
        const avatar =
            name === 'AskNicely' ? this.ANPurpleLogo : notification.senderAvatar

        return {
            id: notification.senderId,
            name,
            avatar,
        }
    }

    public async onNotificationClick(notification: InAppNotification) {
        pendoTrackEvent(
            'mobile-notifications-notification-clicked',
            notification
        )

        if (!notification.read) {
            this.markNotificationAsRead(notification.id)
        }

        if (
            notification.type === 'mention' ||
            notification.type === 'question_changed' ||
            ('questionId' in notification && notification.questionId)
        ) {
            await this.router.push({
                name: 'feedbackdetail',
                params: { feedbackid: notification.questionId?.toString() },
            })
            return
        }

        if (notification.type === 'report_card') {
            this.setShowReportCard(true)
            await this.loadReportCardData(true)
            return
        }

        if (notification.type === 'new_notice') {
            const extra = JSON.parse(notification.extra ?? '{}')
            if ('moment_id' in extra) {
                // If moment id present goes to detail view
                await this.router.push({
                    name: 'momentdetails',
                    params: { momentid: extra.moment_id?.toString() },
                })
                return
            }

            await this.router.push({
                name: 'inbox',
            })
            return
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/mobile/src/styles/misc.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';

@profileHeight: 107px;
@notificationsHeaderHeight: 44px;
@notificationsListHeight: calc(
    100vh - @profileHeight - @notificationsHeaderHeight - @mobileNavHeight -
        env(safe-area-inset-top) - env(safe-area-inset-bottom)
);

.full-width() {
    // Make it full width, ignoring parent container padding
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
}

#notifications-panel {
    max-height: calc(
        @notificationsListHeight + @notificationsHeaderHeight - @featureGutter
    );
    .full-width();

    &.vertical-nav {
        width: calc(100vw - @mobileNavHeight);
    }
}
#notifications-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px @featureGutter @featureGutter;
    border-bottom: 1px solid @thinFog;
}

.header-text {
    color: @white;
    font-weight: @fontWeight-medium;
}

#mark-as-read-button {
    .remove-button-styling();
}

#notifications-list {
    overflow-y: scroll;
    scroll-behavior: smooth;

    /* hide scrollbar */
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; // IE 10+

    &::-webkit-scrollbar {
        display: none;
    }
    max-height: @notificationsListHeight;
    padding-bottom: calc(
        env(safe-area-inset-bottom) - 12px
    ); // For iPhones above iPhone X with gesture bar - we do a custom height, see MobileNavigation.vue
}

.notification {
    padding: @featureGutter @featureGutter @featureGutter 0;
    border-bottom: 1px solid @thinFog;

    display: flex;
    gap: 12px;
    align-items: center;

    .notification-avatar {
        display: flex;
        align-items: center;

        &.read {
            margin-left: 18px;
        }
        .unread-dot {
            width: 6px;
            height: 6px;
            margin: 0 6px;
            background: @white;
            border-radius: 100px;
        }
    }
    .notification-text {
        width: 100%;

        .title {
            font-weight: @fontWeight-medium;
            margin-bottom: 5px;
            color: @white;
        }
        .content {
            color: @white;
            margin-bottom: 14px;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            .footer-text {
                color: @white60;
            }
        }
    }
}

#empty-notifications {
    height: @notificationsListHeight;
}

.inbox-icon {
    font-size: 48px;
    padding-bottom: 12px;
}

.an-purple-logo {
    height: 38px;
    width: 38px;
}
</style>
