import {
    BackgroundFetch,
    BackgroundFetchConfig,
    TaskConfig,
} from '@transistorsoft/capacitor-background-fetch'
import store from '@/mobile/src/store'
import { pendoTrackEvent } from '@/utils/pendo'

const taskId = 'com.transistorsoft.backgroundrefresh'

const onBackgroundFetchEvent = async (taskId: string) => {
    await store.dispatch('fillOfflineCache')
    await BackgroundFetch.finish(taskId)
}

const onBackgroundFetchTimeout = async (taskId: string) => {
    pendoTrackEvent('mobile-offline-cache-timed-out', {
        stage: store.getters.cacheStage,
    })
    await store.dispatch('stopOfflineCache')
    await BackgroundFetch.finish(taskId)
}

const backgroundFetchConfig: BackgroundFetchConfig = {
    minimumFetchInterval: 15,
    requiredNetworkType: 1,
}

const scheduleTaskConfig: TaskConfig = {
    taskId,
    delay: 15 * 60 * 1000,
    periodic: true,
}

const platformSupportsBackgroundTasks = () => {
    return ['ios', 'android'].includes(store.state.device.deviceInfo.platform)
}

export const registerBackgroundTasks = async () => {
    const hasNetworkConnection = store.getters?.hasNetworkConnection
    if (!platformSupportsBackgroundTasks() || !hasNetworkConnection) {
        return
    }

    await BackgroundFetch.configure(
        backgroundFetchConfig,
        onBackgroundFetchEvent,
        onBackgroundFetchTimeout
    )

    await BackgroundFetch.scheduleTask(scheduleTaskConfig)
}

export const stopBackgroundTasks = async () => {
    if (!platformSupportsBackgroundTasks()) {
        return
    }

    await BackgroundFetch.stop()
    await BackgroundFetch.stop(taskId)
}
