<template>
    <div data-test-tag="ts-nps-overall">
        <TsNpsCurrentGroupV2 />
        <TsNpsInfo :is-mobile="isMobile" :hide-tooltip="hideTooltip" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import TsNpsNumber from './TsNpsNumber.vue'
import TsNpsStats from './TsNpsStats.vue'
import TsNpsCurrentGroupV2 from '@/components/TeamScoreboard/TsNpsCurrentGroupV2.vue'
import TsNpsInfo from '@/components/TeamScoreboard/TsNpsInfo.vue'

@Component({
    components: {
        TsNpsInfo,
        TsNpsCurrentGroupV2,
        TsNpsNumber,
        TsNpsStats,
    },
})
export default class TsNpsOverallV2 extends Vue {
    @Prop({ type: Boolean, default: false })
    isMobile
    @Prop({ type: Boolean, default: false })
    hideTooltip
}
</script>
