import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fe1e009"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "response-percentage" }
const _hoisted_2 = {
  key: 0,
  class: "carousel-container"
}
const _hoisted_3 = { class: "response-content" }
const _hoisted_4 = { class: "response-info top" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_ResponseCircle = _resolveComponent("ResponseCircle")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_FeatureContainer = _resolveComponent("FeatureContainer")!

  return (_openBlock(), _createBlock(_component_FeatureContainer, {
    class: _normalizeClass(['feature-container'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "topic-header",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTopicFilteredFeedback()))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_Typography, {
            class: "text topic-subtitle",
            variant: "overline"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.topicSubtitle), 1)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_Typography, {
            class: "text topic-label",
            variant: "h4",
            component: "h4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.topicItem.label), 1)
            ], undefined, true),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Typography, {
            class: "text uppercase",
            variant: "overline"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.topicItem.percentage) + "% of responses", 1)
            ], undefined, true),
            _: 1
          })
        ])
      ]),
      (_ctx.topicItem && _ctx.topicItem.responses.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Carousel, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getResponses(), (response, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "response-container"
                  }, [
                    _createVNode(_component_ResponseCircle, {
                      class: "score-circle",
                      score: response.score,
                      variation: response.type,
                      onClick: ($event: any) => (_ctx.navigateToFeedback(response.id))
                    }, null, 8, ["score", "variation", "onClick"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", {
                          class: "response-text",
                          onClick: ($event: any) => (_ctx.navigateToFeedback(response.id))
                        }, [
                          _createVNode(_component_Typography, {
                            class: _normalizeClass([
                                        'text',
                                        {
                                            'italic-text':
                                                _ctx.isNoCommentResponse(response),
                                        },
                                    ]),
                            variant: "body2"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getResponseComment(response)), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["class"])
                        ], 8, _hoisted_5)
                      ]),
                      _createElementVNode("div", {
                        class: "response-info",
                        onClick: ($event: any) => (_ctx.navigateToFeedback(response.id))
                      }, [
                        _createVNode(_component_Typography, {
                          class: "text uppercase transparent author",
                          variant: "overline"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(response.name), 1)
                          ], undefined, true),
                          _: 2
                        }, 1024),
                        _createVNode(_component_Typography, {
                          class: "text uppercase transparent",
                          variant: "overline"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getRelativeDate(response.responded)), 1)
                          ], undefined, true),
                          _: 2
                        }, 1024)
                      ], 8, _hoisted_6)
                    ])
                  ]))
                }), 128))
              ], undefined, true),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }))
}