import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0597c4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "over-card-container" }
const _hoisted_2 = { class: "over-card-header" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "over-card-content" }
const _hoisted_7 = { class: "over-card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header-left", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "header-center", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "x-close-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
          }, [
            _createVNode(_component_FontAwesomeIcon, {
              class: "xmark",
              icon: _ctx.faXmark
            }, null, 8, ["icon"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ]))
}