export function debounce(func, wait: number, immediate: boolean) {
    let timeout

    return (...args) => {
        const later = () => {
            clearTimeout(timeout)
            timeout = null
            if (!immediate) {
                func(...args)
            }
        }

        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)

        if (callNow) {
            func(...args)
        }
    }
}

export function validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function validatePhone(phone) {
    return /^\+?[\d- ]+$/.test(phone)
}

export function validateUrl(url: string): boolean {
    const pattern =
        /^(https):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,})(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
    return pattern.test(url)
}

export function validateIsUrl(url: string): boolean {
    try {
        const parsedUrl = new URL(url)
        return parsedUrl.protocol === 'https:'
    } catch (error) {
        return false
    }
}

export async function copyToClipboard(content: string): Promise<void> {
    const clipboard = (navigator as any).clipboard
    await clipboard.writeText(content)
}

export function objToFormData(object) {
    if (!object) {
        return new FormData()
    }

    const formData = new FormData()
    Object.keys(object).forEach((key) => {
        const value = object[key]
        // Check if value is an object (and not a file or FormData itself)
        if (
            typeof value === 'object' &&
            !(value instanceof File) &&
            !(value instanceof FormData)
        ) {
            formData.append(key, JSON.stringify(value))
        } else {
            formData.append(key, value)
        }
    })
    return formData
}

export function finalValueChecker(options: any, finalValue: any) {
    if (options && finalValue in options) {
        const option = options[finalValue]
        if (option && 'value' in option && 'label' in option) {
            return option.value
        }
    }
    return finalValue
}

export function isPhoneNumber(emailOrPhone: string) {
    if (!emailOrPhone || emailOrPhone.length === 0) {
        return false
    }
    // If it contains @ symbol it is an email
    return emailOrPhone.indexOf('@') === -1
}
