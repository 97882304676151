<template>
    <div>
        <div>
            <div
                v-for="response in displayResponses"
                :key="response.id"
                class="response can-view-chat"
                @click="onClickResponse(response.id)"
            >
                <!-- circle left -->
                <div class="circle-container">
                    <div :class="getCircleClass(response)" class="circle">
                        {{ response.answer }}
                    </div>
                    <div
                        v-if="answerChange(response) != 0"
                        class="answer-change"
                        data-uk-tooltip="{ pos: 'bottom' }"
                        :title="`Previous ${isFiveScore ? 'Rating' : 'NPS'} ${
                            response.previous_answer
                        }`"
                    >
                        <img
                            v-if="answerChange(response) > 0"
                            src="@/assets/img/Icons/leaderboard-up.svg"
                            alt="green arrow pointing up"
                        />
                        <img
                            v-else-if="answerChange(response) < 0"
                            src="@/assets/img/Icons/leaderboard-down.svg"
                            alt="red arrow pointing down"
                        />
                        <span class="number">&nbsp;{{ Math.abs(answerChange(response)) }}</span>
                    </div>
                </div>
                <!-- text etc right -->
                <div class="right">
                    <!-- comment / answer top -->
                    <div class="comment">
                        {{ getComment(response.comment) }}
                        <span
                            v-if="!response.comment"
                            style="font-style: italic"
                            >No comment</span>
                    </div>
                    <div
                        v-if="response.original_comment"
                        class="moderated-container--tag"
                    >
                        <img src="/img/magic-wand.svg" />
                        Moderated by NiceAI
                    </div>
                    <div class="uk-clearfix"></div>
                    <template v-if="getShowBadges && !hasSelectedBadge">
                        <div
                            v-for="(badge, idx) in response.badges"
                            :key="idx"
                            class="badge"
                            :style="getBadgeStyle(badge)"
                        >
                            {{ getBadge(badge) }}
                        </div>
                    </template>
                    <!-- name and time bottom-->
                    <div class="user-info">
                        <!-- name -->
                        <span class="name">
                            <span
                                v-if="Boolean(Number(response?.dontcontact))"
                                class="do-not-contact"
                                >(Do not contact)</span>
                            {{ getResponseName(response) }}
                        </span>
                        <!-- time since -->
                        <span class="time-since">
                            {{ timeSinceResponded(response.responded) }}
                        </span>
                    </div>
                    <div
                        :class="[
                            'status-icons',
                            {
                                'show-status-icon':
                                    shouldShowStatusIconForResponse(response),
                            },
                        ]"
                    >
                        <div class="icons-left">
                            <img
                                v-if="response.chat_count"
                                class="icon"
                                data-uk-tooltip
                                :title="`${response.chat_count} ${
                                    response.chat_count > 1
                                        ? 'Replies'
                                        : 'Reply'
                                }`"
                                :src="commentIcon(response.chat_outgoing)"
                            />

                            <img
                                v-if="
                                    permissionList?.includes(
                                        'ROLE_VIEW_NOTES'
                                    ) && response.note_count
                                "
                                class="icon"
                                data-uk-tooltip
                                :title="`${response.note_count} Note${
                                    response.note_count > 1 ? 's' : ''
                                }`"
                                src="@/assets/img/Icons/linkicons/note.svg"
                            />

                            <img
                                v-if="response.automation_count"
                                class="icon"
                                data-uk-tooltip
                                :title="`${response.automation_count} Workflow${
                                    response.automation_count > 1 ? 's' : ''
                                }`"
                                src="@/assets/img/Icons/linkicons/workflow.svg"
                            />

                            <img
                                v-if="response.response_count > 1"
                                class="icon"
                                data-uk-tooltip
                                :title="`${response.response_count} Responses`"
                                src="@/assets/img/Icons/linkicons/rewind_time.svg"
                            />
                        </div>
                        <div
                            v-if="canSendShoutout(response)"
                            class="shoutout-button"
                            @click.stop="onClickShoutout(response)"
                        >
                            <img
                                data-uk-tooltip
                                title="Send a Shoutout about this Response"
                                alt="Shoutout"
                                src="@/assets/img/Icons/linkicons/shoutout.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    !loading && (!displayResponses || !displayResponses.length)
                "
                class="no-results"
            >
                No Responses Found
            </div>
        </div>
        <div v-if="loading">
            <Loading :loading="loading" :contained="true"></Loading>
        </div>
        <div v-if="!loading && resultsDepleted" class="no-results">
            No More Responses
        </div>

        <Loading
            v-if="shoutoutNoticeLoading"
            :loading="shoutoutNoticeLoading"
            contained
        />
        <NoticesComposer
            v-else
            reset-on-show
            :show="showNoticeComposer"
            start-step="message"
            @close="closeResponseShoutout"
            @notice-sent="closeResponseShoutout"
        />
    </div>
</template>
<script lang="ts">
import { ref } from 'vue'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import ScorecardBadgeHeading from '@/pages/dashboard/components/Scorecard/ScorecardBadgeHeading.vue'
import Loading from '@/components/Loading.vue'
import { timeSince } from '@/utils/time'
import { getTextEllipsis } from '@/domain/NPS'
import { badgeSchemas } from '@/entities/scorecard'
import CommentIcon from '@/assets/img/Icons/linkicons/comment.svg'
import CommentBlueDotIcon from '@/assets/img/Icons/linkicons/comment_bluedot.svg'
import NoticesComposer from '@/components/Notices/NoticesComposer.vue'
import { IRecipient } from '@/entities/notices'
import { pendoTrackEvent } from '@/utils/pendo'
import { getResponseShoutoutRecipients } from '@/utils/notices'

import { UserDetails } from '@/mobile/src/types/auth'
import { IResponse } from '@/entities'

@Component({
    components: { ScorecardBadgeHeading, Loading, NoticesComposer },
})
export default class DashboardResponsesList extends Vue {
    @Prop({ type: String })
    public displayKey!: string

    @Prop({ default: () => [] })
    public displayResponses?: IResponse[]

    @Prop({ type: String, default: 'nps' })
    public type?: string

    @Prop({ type: String, default: '' })
    public freeText!: string

    @Prop({ type: Boolean, default: true })
    public isFiveScore!: boolean

    @Prop({ type: Boolean, default: true })
    public loading!: boolean

    @Prop({ type: Boolean, default: false })
    public showBadges?: boolean

    @Prop({ type: Boolean, default: false })
    public hasSelectedBadge?: boolean

    @Prop({ type: Boolean, default: false })
    public resultsDepleted?: boolean
    @Prop({ type: Array, default: () => [] }) public permissions!: string[]

    // For mobile
    @Getter('auth/details') userDetails?: UserDetails

    @Getter public $companyVars
    @Getter recipients!: IRecipient[]

    @Action private loadRecipients
    @Action private setComposerQuestion
    @Action private setComposerNoticeType
    @Action private setComposerRecipients

    private showNoticeComposer = false
    public permissionList: string[] | any = []

    private route = useRoute()
    private router = useRouter()

    public mounted() {
        if (!this.permissions.length) {
            // Mobile
            this.permissionList = ref(this.userDetails?.permissions)
        } else {
            // desktop
            this.permissionList = ref(this.permissions)
        }
    }

    public timeSinceResponded(responded) {
        return timeSince(responded)
    }

    public getResponseName(response: IResponse): string {
        return response?.person_name ?? ''
    }

    public async onClickResponse(id) {
        if (this.route.path === '/insights/detail') {
            await this.router.push({
                name: 'chatResponsesRoot',
                params: { id: id },
            })

            return
        }

        const query = { ...this.route.query, chat: id }
        await this.router.push({ path: this.route.path, query })
    }

    shouldShowStatusIconForResponse(response) {
        return (
            response.chat_count ||
            response.note_count ||
            response.automation_count ||
            response.response_count > 1
        )
    }

    public canSendShoutout(response) {
        return response.can_send_response_shoutout
    }

    // color class for cicle
    public getCircleClass(response) {
        if (this.isFiveScore) {
            if (response.answer > 3) {
                return 'positive'
            }
            if (response.answer < 3) {
                return 'negative'
            }

            return 'neutral'
        }
        if (response.answer > 8) {
            return 'positive'
        }
        if (response.answer < 7) {
            return 'negative'
        }

        return 'neutral'
    }

    public answerChange(response): number {
        if (
            response.answer >= 0 &&
            response.previous_answer &&
            response.previous_answer >= 0
        ) {
            return response.answer - response.previous_answer
        }
        return 0
    }

    public getComment(text) {
        return getTextEllipsis(text, 200)
    }

    public getBadge(badge) {
        return badge.label || badge.badge
    }

    public getBadgeStyle(badge) {
        if (!badge) {
            return ''
        }
        if ((badge as any).type === 'positive' && badgeSchemas[badge.badge]) {
            const { foreColor, backColor } = badgeSchemas[badge.badge]
            return { backgroundColor: backColor, color: foreColor }
        } else {
            return { backgroundColor: '#F4F6FC', color: '#A9B4CB' }
        }
    }

    public commentIcon(chatOutgoing) {
        return chatOutgoing ? CommentIcon : CommentBlueDotIcon
    }

    private async onClickShoutout(response) {
        this.setComposerQuestion({
            id: Number(response.id),
            comment: response.comment,
        })
        this.setComposerNoticeType('shoutout')

        // We should only load recipients if shoutout is clicked to avoid loading recipients list unnecessarily.
        if (!this.recipients?.length) {
            await this.loadRecipients()
        }
        this.setComposerRecipients(
            getResponseShoutoutRecipients(this.recipients)
        )
        this.showNoticeComposer = true

        pendoTrackEvent('response_shoutout_started')
    }

    private closeResponseShoutout() {
        this.showNoticeComposer = false
        this.setComposerQuestion(null)
    }

    public get shoutoutNoticeLoading() {
        return this.showNoticeComposer && !this.recipients.length
    }

    public get getShowBadges() {
        if (this.$companyVars.has_ai_themes_for_scorecard) {
            return false
        }
        return this.showBadges
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.response {
    &.can-view-chat {
        cursor: pointer;
    }

    padding: 20px;
    display: flex;
    border-bottom: 1px solid rgba(46, 91, 255, 0.08);

    .circle-container {
        display: flex;
        align-items: center;
        padding-right: 20px;
        flex-direction: column;

        .circle {
            width: 40px;
            height: 40px;
            background: #edeeef;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }

        .answer-change {
            margin-top: 4px;

            img {
                width: 6px;
                height: 5px;
            }

            .number {
                font-size: 10px;
                line-height: 16px;
            }
        }

        .positive {
            background-color: #c6f7e2;
            color: #1fdf8f;
        }

        .neutral {
            background-color: #edeeef;
            color: #9399a2;
        }

        .negative {
            background-color: #ffe3e3;
            color: #ef4e4e;
        }
    }

    .right {
        flex-grow: 1;

        .comment {
            font-size: 15px;
            line-height: 22px;
        }

        .user-info {
            margin-top: 10px;
            display: flex;

            .name {
                color: #b0bac9;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.13px;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0;
                flex: 10 10 0;
                max-width: 150px;
            }

            .do-not-contact {
                font-size: @fontSize-xs;
                background-color: #fff9e0;
                margin-left: 5px;
            }

            .time-since {
                color: #bfc5d2;
                font-size: 12px;
                text-align: right;
                padding-left: 5px;
                flex: 1 0 auto;
            }
        }

        .status-icons {
            display: flex;
            margin-top: 4px;
            justify-content: space-between;

            .icons-left {
                display: flex;
                align-items: center;
            }

            .icon {
                margin-right: 8px;
            }

            .shoutout-button {
                float: right;
                height: 30px;
                border-radius: @borderRadius-base;
                border: 1px solid @outline;
                width: 32px;
                align-items: center;
                justify-content: center;
                display: flex;
            }
        }
    }
}

.no-results {
    color: #999;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-style: italic;
}

.badge {
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    display: inline-block;
    padding: 2px;
    position: relative;
    left: -2px;
    margin: 12px 5px 6px 0;
    text-transform: capitalize;
}

.moderated-container {
    &--tag {
        display: inline-flex;
        align-items: center;
        background-color: #f4ebff;
        padding: 2px 8px;
        border-radius: 4px;
        color: #333;
        font-size: 10px;
        margin-top: @gutterSpacing-base;
        img {
            height: 12px;
            width: auto;
            margin-right: 6px;
        }
    }
}
</style>
