import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d5ae33a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewAsNavBar = _resolveComponent("ViewAsNavBar")!
  const _component_MobileNavigationTab = _resolveComponent("MobileNavigationTab")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['container', `${_ctx.navBarPosition}-nav`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      ref: "tabsContainer",
      class: _normalizeClass([
                'tabs',
                { solid: _ctx.route.name === 'huddles' },
                _ctx.navBarPosition,
            ])
    }, [
      _createVNode(_component_ViewAsNavBar),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_MobileNavigationTab, {
          key: tab.text,
          class: "tab-option",
          tab: tab,
          selected: _ctx.selected === tab.name || tab.name === _ctx.routeGroup,
          onClick: _ctx.onClickTab
        }, null, 8, ["tab", "selected", "onClick"]))
      }), 128))
    ], 2)
  ], 2))
}