<template>
    <BaseBlock>
        <template #title>
            <div class="title-container">
                <span>Team Scoreboard</span>

                <label v-if="tsTeamGroups.length > 1" class="label">
                    <select
                        v-model="selectedTeamGroupKey"
                        class="input"
                        @change="selectGroup"
                    >
                        <option
                            v-for="group in tsTeamGroups"
                            :key="group.groupName"
                            :value="getTeamgroupKey(group)"
                        >
                            {{ group.groupName }}
                        </option>
                    </select>
                </label>
            </div>
            <SearchBox
                :value="searchText"
                :rounded="false"
                @input="searchText = $event"
            />
            <MobileSelectMetric class="metric-selector" no-pad />
        </template>

        <NPSResponsesPlot :team-scoreboard="teamScoreboard" />
        <NPSPlotValueList
            :ts-group-summary="tsGroupSummary"
            :visible-items="visibleItems"
            :fields="fields"
            @showDetail="$emit('showDetail')"
        />
    </BaseBlock>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Getter, Action, Mutation } from 'vuex-facing-decorator'
import {
    TeamScoreboardEntity,
    TsFilterEntity,
    TsFilterOperators,
    TsFilterTypes,
    TsGroupEntity,
    TsGroupSummaryEntity,
} from '@/entities/teamscoreboard'
import BaseBlock from '@/components/BaseBlock.vue'
import NPSResponsesPlot from '@/pages/team-scoreboard/components/NPSResponsesPlot.vue'
import NPSPlotValueList from '@/pages/team-scoreboard/components/NPSPlotValueList.vue'
import MobileSelectMetric from '@/components/MobileSelectMetric.vue'
import SearchBox from '@/components/SearchBox.vue'
import { debounce } from '@/utils/function'
import { CompanyFeatures } from '@/entities'

@Component({
    components: {
        BaseBlock,
        NPSResponsesPlot,
        NPSPlotValueList,
        SearchBox,
        MobileSelectMetric,
    },
})
export default class TheTeamPerformanceBlock extends Vue {
    @Getter public tsTeamGroups
    @Getter public teamScoreboard?: TeamScoreboardEntity
    @Getter public tsGroupSummary?: TsGroupSummaryEntity
    @Getter public isLeafGroup
    @Getter public tsFilters?: TsFilterEntity[]
    @Getter public filterLastPatch
    @Getter public mobileQuestionType

    @Action public loadTeamScoreboard
    @Action public loadTsTeamGroups
    @Action public loadTsGroupSummaryFromScoreboard
    @Action private setTsFilter
    @Action private clearTsFilterOfType
    @Action public loadTsNpsSummaryFromScorecard

    @Mutation public setZoomIn

    public selectedTeamGroupKey = ''
    private visibleItems: TsGroupEntity[] = []
    private fields = [] as any
    private searchText = ''

    public async mounted() {
        await this.loadTsTeamGroups()
        this.loadTeamScoreboard()
    }

    public selectGroup() {
        const selectedGroup = this.tsTeamGroups.find(
            (group) => this.getTeamgroupKey(group) === this.selectedTeamGroupKey
        )
        this.loadTeamScoreboard({
            selected: {
                field: selectedGroup.fieldMapping,
                role: selectedGroup.role,
            },
        })
    }

    public getTeamgroupKey(teamgroup) {
        return teamgroup ? `${teamgroup.fieldMapping}-${teamgroup.role}` : ''
    }

    @Watch('teamScoreboard')
    @Watch('mobileQuestionType')
    @Watch('filterLastPatch')
    private async onTeamScoreboardChange() {
        if (!this.teamScoreboard) {
            return
        }
        const currentGroup = this.tsTeamGroups.find(
            (group) =>
                !!this.teamScoreboard &&
                group.fieldMapping === this.teamScoreboard.field &&
                group.role === this.teamScoreboard.role
        )

        this.selectedTeamGroupKey = this.getTeamgroupKey(currentGroup)
        if (!this.isLeafGroup) {
            this.setZoomIn(false)
        }

        await this.loadData()
    }

    // These 2 watch decorators (filterLastPatch and groupListFilters) directly taken from TsNpsGroupList.vue
    @Watch('teamScoreboard')
    @Watch('mobileQuestionType')
    @Watch('filterLastPatch')
    private async loadData() {
        await Promise.allSettled([
            this.loadTsGroupSummaryFromScoreboard(),
            this.loadTsNpsSummaryFromScorecard(),
        ])
    }

    @Watch('tsGroupSummary')
    @Watch('tsFilters')
    private checkFilters() {
        this.visibleItems = []

        if (
            !this.tsGroupSummary ||
            !this.tsGroupSummary.tsgroups ||
            this.tsGroupSummary.tsgroups.length <= 0
        ) {
            return
        }

        this.visibleItems = this.tsGroupSummary.tsgroups.filter(
            (currentGroup) => this.checkFilter(currentGroup)
        )
        this.fields = [
            ...new Set(this.tsGroupSummary.tsgroups.map((item) => item.field)),
        ]
    }

    @Watch('searchText')
    private searchTextChanged() {
        this.debouncedUpdateFilter()
    }

    private checkFilter(currentGroup: TsGroupEntity): boolean {
        if (!this.tsFilters || this.tsFilters.length <= 0) {
            return true
        }

        const found = this.tsFilters.find((current: TsFilterEntity) => {
            const foundFilter = current.filters.find((filter: string) => {
                switch (current.operator) {
                    case TsFilterOperators.equals:
                        return (
                            filter.toLowerCase() ===
                            currentGroup.group.toLowerCase()
                        )
                    case TsFilterOperators.contains:
                        return currentGroup.group
                            .toLowerCase()
                            .includes(filter.toLowerCase())
                }
            })

            return typeof foundFilter !== 'undefined'
        })

        return typeof found !== 'undefined'
    }

    private debouncedUpdateFilter: () => void = debounce(
        this.updateFilter,
        100,
        false
    )

    private updateFilter() {
        if (this.searchText) {
            this.setTsFilter({
                type: TsFilterTypes.search,
                operator: TsFilterOperators.contains,
                filters: [this.searchText],
            })
        } else {
            this.clearTsFilterOfType(TsFilterTypes.search)
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette';
@import '~@/styles/typography';

.title-container {
    // TODO: base this value on a variable
    margin-bottom: 8px;
}

.metric-selector {
    // TODO: base this value on a variable
    margin-top: 16px;
}

.label {
    position: relative;
    margin-left: 5px;

    &:after {
        background-image: url('~@/assets/img/Icons/v3/chevron-down-blue.svg');
        background-repeat: no-repeat;
        content: '';
        height: 6px;
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
    }
}

.input {
    .title();
    appearance: none;
    background: none;
    border: none;
    color: @blue;
    outline: none;
    padding-right: 14px;
}
</style>
