<template>
    <div
        class="teams-filter-container"
        @click.stop="$emit('filterLostFocus', false)"
    >
        <div class="teams-filter-top">
            <Typography
                component="span"
                class="back navigation-text"
                @click="backAction"
            >
                <template v-if="backLabel">
                    <FontAwesomeIcon
                        class="chevron-left"
                        size="lg"
                        :icon="faChevronLeft"
                    />
                    {{ backLabel }}
                </template>
            </Typography>
            <Transition name="title" mode="out-in">
                <Typography :key="title" component="span" class="title-text">
                    {{ title }}
                </Typography>
            </Transition>
            <Typography
                v-if="hasNetworkConnection"
                component="span"
                class="navigation-text filter"
                @click="toggleFilterVisibility"
            >
                FILTERS
            </Typography>
            <span v-else class="navigation-text filter" />
        </div>
        <transition name="slide-down">
            <div v-show="showFilters">
                <div class="teams-filter-middle">
                    <hr class="divider" />
                    <!-- Hierarchy -->
                    <div class="filter-set-container">
                        <Typography
                            component="div"
                            class="navigation-text filter-set-label"
                        >
                            View {{ tenant }} By
                        </Typography>
                        <div ref="filterOptions" class="filter-set">
                            <div class="filter-set-options">
                                <PillButton
                                    v-for="(option, index) in filterOptions"
                                    :key="index"
                                    :label="
                                        option.groupName ??
                                        option.fieldMapping ??
                                        ''
                                    "
                                    :disabled="shouldDisableOption(option)"
                                    class="filter-button"
                                    :class="
                                        filterIsSelected(option)
                                            ? 'selected'
                                            : ''
                                    "
                                    @click="
                                        toggleTeamFilterSelected(option, false)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <!-- /Hierarchy -->
                    <!-- Time Filters -->
                    <div class="filter-set-container">
                        <Typography
                            component="div"
                            class="navigation-text filter-set-label"
                        >
                            Time Filter
                        </Typography>
                        <div ref="timeFilterOptions" class="filter-set">
                            <div class="filter-set-options">
                                <PillButton
                                    v-for="(option, index) in timeFilterOptions"
                                    :key="index"
                                    :label="option.label ?? ''"
                                    class="filter-button"
                                    :class="
                                        timeFilterIsSelected(option)
                                            ? 'selected'
                                            : ''
                                    "
                                    @click="toggleTimeFilterSelected(option)"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- /Time Filters -->
                </div>
                <hr class="divider" />
                <div class="teams-filter-bottom">
                    <Typography
                        component="span"
                        class="navigation-text"
                        @click="resetFilters"
                    >
                        RESET FILTERS
                    </Typography>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { nextTick } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faChevronLeft } from 'fontawesome/free-solid-svg-icons'
import PillButton from '@/components/PillButton.vue'
import { Getter, namespace } from 'vuex-facing-decorator'
import { TsTeamGroupEntity } from '@/entities/teamscoreboard'
import { scrollActiveFiltersIntoView } from '@/mobile/src/utils/misc'

const TeamFilterModule = namespace('teamFilter')
const TimeFilterModule = namespace('timeFilter')

@Component({
    components: {
        PillButton,
        FontAwesomeIcon,
        Typography,
    },
    emits: ['filterLostFocus', 'resetFilters', 'toggleFilter', 'backAction'],
})
export default class TeamsFilter extends Vue {
    @Prop({ type: String, default: null }) public filterLevelTitle!: string
    @Prop({ type: String, default: null }) public title!: string
    @Prop({ type: String, default: null }) public tenant!: string
    @Prop({ type: String, default: null }) public backLabel!: string
    @Prop({ type: Boolean, default: true })
    public hasNetworkConnection!: boolean

    @Getter public $companyVars

    private faChevronLeft = faChevronLeft
    private showFilters = false

    @TeamFilterModule.Getter filterOptions
    @TeamFilterModule.Action loadFilterOptions
    @TeamFilterModule.Action toggleSelected
    @TeamFilterModule.Getter filterIsSelected
    @TeamFilterModule.Getter backToPrevious
    @TeamFilterModule.Action resetTeamFilters

    @TimeFilterModule.Action initTimeFilterOptions
    @TimeFilterModule.Getter timeFilterOptions
    @TimeFilterModule.Getter timeFilterIsSelected
    @TimeFilterModule.Action toggleTimeSelected
    @TimeFilterModule.Action resetTimeFilter

    @Getter userRole
    @Getter teamFilterAdminRight

    async mounted() {
        this.initTimeFilterOptions()
        this.loadFilterOptions(!this.teamFilterAdminRight)
    }

    toggleFilterVisibility() {
        this.showFilters = !this.showFilters
        if (this.showFilters) {
            nextTick(() => scrollActiveFiltersIntoView())
        }
    }

    resetFilters() {
        this.resetTeamFilters()
        const history = this.backToPrevious
        // highlight the team filter button.
        this.toggleSelected(history)

        this.resetTimeFilter()
        this.$emit('resetFilters')
    }

    public toggleTeamFilterSelected(
        option: TsTeamGroupEntity,
        fromBackButton = false
    ) {
        this.$emit('toggleFilter', option, fromBackButton)
        if (!fromBackButton) {
            this.toggleFilterVisibility()
        }
    }

    public toggleTimeFilterSelected(option) {
        this.toggleTimeSelected(option)
        this.toggleFilterVisibility()
    }

    public backAction(): void {
        this.$emit('backAction')
    }

    public shouldDisableOption(option: TsTeamGroupEntity) {
        return this.userRole?.includes(option.groupName)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/zindex.less';

.teams-filter-container {
    padding-top: env(safe-area-inset-top);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    z-index: @filterHeaderIndex;

    .teams-filter-top {
        padding: @featureGutter;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title-text {
            flex: 2;
            align-items: center;
            color: @white;
            font-size: @fontSize-md;
            font-weight: @fontWeight-medium;
            letter-spacing: @letterSpacing-base;
            line-height: @lineHeight-md;
            height: @lineHeight-md;
            text-align: center;
            text-transform: capitalize;
        }

        .back,
        .filter {
            flex: 1;
            width: 100%;
        }
        .filter {
            text-align: right !important;
            margin: auto;
        }

        .title-enter-active,
        .title-leave-active {
            transition: all 0.45s ease-out;
        }
        .title-enter-from {
            opacity: 0;
            transform: translateY(30px);
        }
        .title-leave-to {
            opacity: 0;
            transform: translateY(30px);
        }
    }

    .navigation-text {
        align-items: center;
        color: @white;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-2xl;
        text-transform: uppercase;

        &.back {
            align-items: center;
            display: inline-flex;

            .chevron-left {
                margin-right: 5px;
            }
        }
    }

    .teams-filter-middle {
        display: flex;
        flex-direction: column;
        text-align: left;

        .filter-set-container {
            padding-top: 25px;

            &:last-of-type {
                padding-bottom: 30px;
            }
            .filter-set-label {
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 10px;
                padding-left: 20px;
            }
            .filter-set {
                display: flex;
                flex-direction: column;
                justify-content: left;
                overflow-x: auto;
                scroll-padding: 50px;
                scrollbar-width: none;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: none;

                /* Hide the scrollbar thumb */
                &::-webkit-scrollbar {
                    display: none;
                }

                .filter-set-options {
                    display: inline-flex;
                    flex-direction: row;
                    gap: 17px;
                    width: max-content;

                    .filter-button {
                        border: 1px solid #fff;
                        background-color: rgba(0, 0, 0, 0);
                        color: #fff;
                        font-size: @fontSize-sm;
                        font-weight: @fontWeight-medium;
                        height: 28px;
                        letter-spacing: @letterSpacing-xl;
                        line-height: 12px;
                        padding-left: 16px;
                        padding-right: 16px;
                        text-transform: uppercase;

                        &:first-of-type {
                            margin-left: 20px;
                        }

                        &:last-of-type {
                            margin-right: 20px;
                        }

                        &.selected {
                            background-color: #fff;
                            color: @indigo;
                        }

                        &.disabled {
                            background-color: rgba(0, 0, 0, 0);
                        }
                    }
                }
            }
        }
    }

    .divider {
        margin: 0;
        border: 0;
        border-top: 1px solid rgba(241, 243, 248, 0.3);
    }

    .teams-filter-bottom {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
    }
}

.slide-down-enter-active,
.slide-down-leave-active {
    max-height: 1000px;
    overflow-y: hidden;
    transition: all 0.3s;
}
.slide-down-enter-from,
.slide-down-leave-to {
    max-height: 0px;
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
