<template>
    <div>
        <LoadingCard v-if="loadingCaseOptions" variant="single" borderless />
        <div v-else class="feedback-case-management-container">
            <div v-if="!isCaseClosed">
                <ActionButton
                    v-for="(caseOption, index) in caseOptions"
                    :key="index"
                    :label="'Close via ' + caseOption"
                    :on-click="() => openOrCloseCase(true, caseOption)"
                />
            </div>

            <ActionButton
                v-if="isCaseClosed"
                label="🔔 Re-open case"
                :on-click="() => openOrCloseCase(false)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-facing-decorator'
import { Component, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import { getCaseOptions, toggleCaseClosed } from '@/api/chat'
import { showToast, hideToast } from '@/mobile/src/utils/toastbus'
import { ToastColor, ToastDuration, ToastIcon } from '@/mobile/src/types/toasts'
import Typography from '@/components/Rain/Typography/Typography.vue'
import ActionButton from '@/mobile/src/components/appV4/ActionButton.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { sleep } from '@/utils/async'

@Component({
    components: {
        LoadingCard,
        ActionButton,
        Typography,
    },
})
export default class FeedbackCaseManagement extends Vue {
    @Getter public chatCustomData
    @Action protected getChatQuestion!: (id: number) => void
    @Action private reloadChatCustomData
    @Action public setChatCustomData
    @Action public setChatQuestion!: (id: number) => void
    @Action public loadChat!: () => Promise<void>

    private caseOptions: string[] = []
    public loadingCaseOptions = false

    private route = useRoute()
    private router = useRouter()
    private emitter = useEmitter()

    private get questionId(): number {
        return Number(this.route.params.feedbackid)
    }

    private get isCaseClosed(): boolean {
        return Number(this.chatCustomData.case_closed) === 1
    }

    public async mounted(): Promise<void> {
        if (
            Object.keys(this.chatCustomData).length === 0 &&
            this.chatCustomData.constructor === Object
        ) {
            await this.setChatQuestion(this.questionId)
        }

        if (this.caseOptions.length === 0) {
            this.loadingCaseOptions = true
            const { data } = await getCaseOptions()
            this.caseOptions = data
            this.loadingCaseOptions = false
        }
    }

    public async openOrCloseCase(
        open: boolean,
        closeOption?: string
    ): Promise<void> {
        const loadingToast = showToast(
            this.emitter,
            ToastIcon.Waiting,
            ToastColor.InternalNote,
            `${!open ? 'Opening' : 'Closing'} Case`
        )

        try {
            await toggleCaseClosed(
                this.questionId,
                open,
                open ? closeOption : null
            )

            await Promise.allSettled([
                this.reloadChatCustomData(),
                this.loadChat(),
            ])

            hideToast(this.emitter, loadingToast)
            showToast(
                this.emitter,
                ToastIcon.Success,
                ToastColor.InternalNote,
                `Case ${!open ? 'Opened' : 'Closed'}`,
                ToastDuration.Standard
            )
            await sleep(ToastDuration.Standard)

            this.router.back()
        } catch (error) {
            showToast(
                this.emitter,
                ToastIcon.Alert,
                ToastColor.Warning,
                `There was an error while ${
                    !open ? 'opening' : 'closing'
                } the case`,
                ToastDuration.Standard
            )
            hideToast(this.emitter, loadingToast)
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';

.feedback-case-management-container {
    width: calc(100% + @featureGutter * 2);
    position: relative;
    left: -@featureGutter;
}
</style>
