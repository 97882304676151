<template>
    <div class="huddles-card promotion">
        <div class="promotion-header">
            <div class="promotion-title">
                <FontAwesomeIcon size="lg" :icon="faTag" /> Promotion
            </div>
            <div
                v-if="!editingPromotion && isAdmin"
                class="promotion-edit-button"
                @click="updatePromotion"
            >
                <FontAwesomeIcon
                    v-if="!editingPromotion"
                    size="xs"
                    :icon="faPencil"
                />
                Edit
            </div>
        </div>
        <div class="promotion-body">
            <Textarea
                v-if="editingPromotion"
                v-model="draftPromotion"
                type="text"
                rows="3"
            />
            <div v-if="!editingPromotion" class="p-message">
                {{ promotionMessage }}
            </div>
        </div>
        <div v-if="editingPromotion" class="promotion-footer">
            <div class="promotion-cancel-button" @click="cancelPromotion">
                Cancel
            </div>
            <div
                v-if="editingPromotion"
                class="promotion-save-button"
                @click="updatePromotion"
            >
                <FontAwesomeIcon
                    v-if="editingPromotion"
                    size="xs"
                    :icon="faFloppyDisk"
                />
                Save
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faFloppyDisk,
    faTag,
    faPencil,
} from 'fontawesome/pro-regular-svg-icons'
import Textarea from 'primevue/textarea'
import { computed, type ComputedRef, ref } from 'vue'
import { useStore } from 'vuex'
import { Permissions } from '@/mobile/src/types/auth'
import { sendPromotion } from '@/api/huddles'

interface Props {
    promotionMessage: string
}
const props = defineProps<Props>()
const draftPromotion = ref(props.promotionMessage)
const editingPromotion = ref(false)
const store = useStore()

const emit = defineEmits(['promotion-updated'])

const cancelPromotion = () => {
    draftPromotion.value = props.promotionMessage
    editingPromotion.value = false
}

const isAdmin: ComputedRef<boolean> = computed(() =>
    store.getters['permissions']?.includes(Permissions.RoleAdminBasics)
)

const updatePromotion = async () => {
    if (
        editingPromotion.value &&
        props.promotionMessage !== draftPromotion.value
    ) {
        await sendPromotion(draftPromotion.value)
        emit('promotion-updated')
    }
    editingPromotion.value = !editingPromotion.value
}
</script>

<style lang="less" scoped>
@import '@/styles/huddles.less';
@import '@/styles/rain/variables.less';
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/colour.less';

.promotion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.promotion-save-button,
.promotion-edit-button,
.promotion-cancel-button,
.p-textarea,
.p-message {
    font-size: @fontSize-sm;
    font-weight: @fontWeight-regular;
    line-height: @lineHeight-sm;
    letter-spacing: @letterSpacing-lg;
}

.promotion-footer,
.promotion-header {
    color: var(--p-gray-600);
    font-size: @fontSize-md;
    font-weight: @fontWeight-medium;
    line-height: @lineHeight-md;
    letter-spacing: @letterSpacing-base;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .promotion-save-button,
    .promotion-edit-button,
    .promotion-cancel-button {
        display: flex;
        align-items: center;
        gap: @spacing-xs;
        color: @successGreen;
    }

    .promotion-cancel-button {
        justify-content: left;
    }
    .promotion-save-button,
    .promotion-edit-button {
        justify-content: flex-end;
    }
}
.promotion-body {
    .p-textarea,
    .p-message {
        color: var(--p-gray-900);
    }

    .p-textarea {
        width: 95%;
        height: 100%;
        margin-left: -2.5%;
        resize: none;
    }
}
</style>
