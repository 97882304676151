<template>
    <div class="">
        <LoadingCard
            v-if="loading || teamFilterSelected === null"
            :borderless="false"
            variant="columns"
        />
        <div
            v-if="!loading && tsScorecard && tsScorecard.tsgroups.length > 0"
            class="teams-topics"
        >
            <div class="content">
                <div class="title-bar">
                    <Typography
                        v-if="customerLoves.length"
                        variant="overline"
                        class="topic-title"
                    >
                        {{ title }}
                    </Typography>
                    <Typography variant="subtitle2" class="topic-tab">
                        <span
                            v-if="
                                !$companyVars.has_individually_rated_scorecard
                            "
                            class="count-metric"
                            :class="[
                                displayState === 'COUNT'
                                    ? 'count-metric-selected'
                                    : 'dim',
                            ]"
                            @click="setDisplay('COUNT')"
                            >COUNT</span>
                        <span
                            class="count-metric"
                            :class="[
                                displayState === 'METRIC'
                                    ? 'count-metric-selected'
                                    : 'dim',
                            ]"
                            @click="setDisplay('METRIC')"
                        >
                            {{ scoreType }}
                        </span>
                    </Typography>
                </div>

                <div v-if="displayState === 'COUNT'" class="grid-container">
                    <Typography
                        variant="overline"
                        class="grid-item success-green total"
                        >Customer Love</Typography>
                    <Typography
                        variant="overline"
                        class="grid-item warning-orange total"
                        >Focus Area</Typography>

                    <div v-if="customerLoves.length > 0">
                        <div
                            v-for="(topic, topicIdx) in customerLoves"
                            :key="topicIdx"
                            class="grid-item"
                            @click="
                                showTopicFilteredFeedback(topic, 'promoters')
                            "
                        >
                            <div class="topic-title">
                                <FontAwesomeIcon
                                    :icon="faArrowCircleRight"
                                    size="xs"
                                    class="group-arrow-icon"
                                />
                                <Typography variant="body2">
                                    {{ topic.group }}
                                </Typography>
                            </div>

                            <div
                                class="magnitude-line success-green-as-background"
                                :style="{
                                    width:
                                        asPercentage(
                                            topic.respondedPromoter,
                                            totalCustomersLove()
                                        ) + '%',
                                }"
                            />
                            <span class="topic-item-title">{{
                                    asPercentage(
                                        topic.respondedPromoter,
                                        totalCustomersLove()
                                    )
                                }}%</span>
                        </div>
                    </div>

                    <div>
                        <div
                            v-for="(topic, topicIdx) in focusAreas"
                            :key="topicIdx"
                            class="grid-item"
                            @click="
                                showTopicFilteredFeedback(topic, 'detractors')
                            "
                        >
                            <div class="topic-title">
                                <FontAwesomeIcon
                                    :icon="faArrowCircleRight"
                                    size="xs"
                                    class="group-arrow-icon"
                                />
                                <Typography variant="body2">
                                    {{ topic.group }}
                                </Typography>
                            </div>

                            <div
                                class="magnitude-line warning-orange-as-background"
                                :style="{
                                    width:
                                        asPercentage(
                                            topic.respondedDetractor,
                                            totalFocusAreas()
                                        ) + '%',
                                }"
                            />
                            <span class="topic-item-title">{{
                                    asPercentage(
                                        topic.respondedDetractor,
                                        totalFocusAreas()
                                    )
                                }}%</span>
                        </div>
                    </div>
                    <Typography
                        variant="overline"
                        class="grid-item success-green total"
                        >Total<br />{{ totalCustomersLove() }}</Typography>
                    <Typography
                        variant="overline"
                        class="grid-item warning-orange total"
                        >Total<br />{{ totalFocusAreas() }}</Typography>
                </div>

                <div
                    v-if="displayState === 'METRIC'"
                    class="nps-container"
                    style="overflow: hidden"
                >
                    <div
                        v-for="(topic, index) in tsScorecard.tsgroups"
                        v-show="tsScorecard"
                        :key="index"
                        class="grid-itemx"
                        @click="showTopicFilteredFeedback(topic)"
                    >
                        <NPSLineGraph
                            :group="topic.group"
                            :average="Number(tsScorecard.averageNPS)"
                            :max="Number(tsScorecard.maxNPS)"
                            :min="Number(tsScorecard.minNPS)"
                            :nps="Number(topic.NPS)"
                            :score-type="scoreType"
                            :previous-nps="Number(topic.previousNPS)"
                            :show-goal="Boolean(index === 0)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faArrowCircleRight } from 'fontawesome/free-solid-svg-icons'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import {
    TeamScoreboardEntity,
    TsNpsSummaryEntity,
    TsTeamGroupEntity,
    TsGroupEntity,
    TsGroupSummaryEntity,
} from '@/entities/teamscoreboard'
import { IFilterOption } from '@/entities/teamFilter'
import Typography from '@/components/Rain/Typography/Typography.vue'
import NPSLineGraph from '@/mobile/src/components/appV4/teams/NPSLineGraph.vue'
import { getDateRange } from '@/utils/scorecard'
import { pendoTrackEvent } from '@/utils/pendo'
import { getFiltersForTopicFeedback } from '@/mobile/src/utils/topics'
import { IFilterRule } from '@/entities'
import { OFFLINE_FEATURE_DISABLE_MESSAGE } from '@/utils/string'

const TeamFilterModule = namespace('teamFilter')
const TimeFilterModule = namespace('timeFilter')
const ScoredataModule = namespace('scoredata')

@Component({
    components: {
        NPSLineGraph,
        Typography,
        LoadingCard,
        FontAwesomeIcon,
    },
    emits: ['refresh-finished'],
})
export default class Topics extends Vue {
    @Prop({ type: Boolean, default: false }) public shouldRefresh!: boolean
    @Prop({ type: String, default: '' }) public filterKey!: string
    // Displayed at the top of the card
    @Prop({ type: String, default: 'Team Scorecard' }) public title!: string
    // When true, includes extra filter for /teamscoreboard/groups to limit data to self instead of the whole team
    @Prop({ type: Boolean, default: false }) public selfOnlyFilter!: boolean
    @Prop({ type: String, default: '30' }) public readonly scoreDays?: string

    private loading = true
    public faArrowCircleRight = faArrowCircleRight
    displayState = 'METRIC'

    @Action loadTsScorecard
    @Getter tsScorecard?: TsGroupSummaryEntity
    @Getter teamScoreboard?: TeamScoreboardEntity

    @Action public loadTeamScoreboard
    @Action public setCacheEnabled
    @Getter public $companyVars
    @Action public setTopicFeedbackFilters
    @Action public setTopicFeedbackTimeOption

    @TeamFilterModule.Getter teamFilterSelected?: TsTeamGroupEntity
    @TeamFilterModule.Getter teamParentFilterSelected
    @TeamFilterModule.Getter teamFilterLoading
    @TimeFilterModule.Getter timeFilterSelected?: IFilterOption
    @TeamFilterModule.Getter teamParentLoading
    @TeamFilterModule.Getter filterOptions

    @ScoredataModule.Getter scoreType
    @Getter userName
    @Getter userRole
    @Getter teamFilterAdminRight

    @Getter hasNetworkConnection!: boolean

    private customerLoves: TsGroupEntity[] = []
    private focusAreas: TsGroupEntity[] = []

    private route = useRoute()
    private router = useRouter()

    @Watch('filterKey')
    @Watch('scoreType')
    @Watch('teamParentLoading')
    public async filterChanged() {
        if (!this.teamParentLoading) {
            await this.loadData()
        }
    }

    async mounted() {
        if (!this.teamParentLoading) {
            // Load on mount if:
            // Self Scorecard, while loading, or
            // Team Scorecard, while parent loading
            await this.loadData()
        }
    }

    @Watch('shouldRefresh')
    public async forceRefresh() {
        if (this.shouldRefresh) {
            await this.loadData()
            this.$emit('refresh-finished')
        }
    }

    public async loadData() {
        this.loading = true
        if (this.teamFilterLoading || !this.scoreType) {
            this.loading = false
            return
        }

        await this.loadTopicsSummary()
    }

    private get extraFilters(): IFilterRule[] {
        let extraFilters: IFilterRule[] = []
        let column = this.teamFilterSelected?.fieldMapping
        let value = this.teamFilterSelected?.customSelectionName
        // Login as frontline manager or staff, view Teams page.
        // load team scorecard data of the team, instead of current user only
        if (this.teamParentFilterSelected && !this.selfOnlyFilter) {
            column = this.teamParentFilterSelected?.fieldMapping
            value = this.teamParentFilterSelected?.rankWithinName
        }

        const extraFilter: IFilterRule = {
            column: column ?? '',
            operator: 'in',
            value: [value ?? ''],
        }

        if (!extraFilter.value.includes('') && extraFilter.column !== '') {
            extraFilters.push(extraFilter)
        }

        // Only set the filter if current user role link to hierarchy.
        // Or has Admin right.
        if (
            this.selfOnlyFilter &&
            (this.userRole?.includes(this.teamFilterSelected?.groupName) ||
                this.teamFilterAdminRight)
        ) {
            // reset filters.
            extraFilters = []

            if (!this.teamFilterAdminRight) {
                extraFilters.push({
                    column: this.filterOptions[0]?.fieldMapping,
                    operator: 'in',
                    value: [this.userName],
                })
            }
        }

        return extraFilters
    }

    private async loadTopicsSummary() {
        if (!this.teamFilterSelected || !this.timeFilterSelected) {
            this.loading = false
            return
        }
        this.setCacheEnabled(true)

        let extraFilters = this.extraFilters

        // Include any extra filters from the route
        const extraFiltersFromRoute = this.route.params.extraFilter
            ? this.route.params.extraFilter
            : ''
        if (extraFiltersFromRoute !== '') {
            try {
                const extraFiltersFromRouteObject = JSON.parse(
                    extraFiltersFromRoute.toString()
                )
                extraFilters.push({
                    column: extraFiltersFromRouteObject.column,
                    operator: extraFiltersFromRouteObject.operator,
                    value: extraFiltersFromRouteObject.value,
                } as IFilterRule)
            } catch (e) {
                // Do nothing
            }
        }

        // load this.teamScoreboard data
        // if select Team Filter item.
        const params = {
            field: this.teamFilterSelected?.fieldMapping,
            role: this.teamFilterSelected?.role,
            forceRefresh: this.shouldRefresh,
        }

        if (
            this.teamFilterSelected?.customSelectionName ||
            this.teamParentFilterSelected
        ) {
            // Topmost level, or not drilled down.
            await this.loadTeamScoreboard({
                parent: params,
            })
        } else {
            await this.loadTeamScoreboard({
                selected: params,
            })
        }

        // For Overview, get "scoreDays" data. For Teams, "timeFilter" is applied
        const daysForScoreboard = this.selfOnlyFilter
            ? this.scoreDays
            : this.timeFilterSelected?.value
        await this.loadTsScorecard({
            field: this.teamScoreboard?.scorecardField,
            extraFilters: extraFilters,
            daterange: getDateRange(Number(daysForScoreboard)),
            forceRefresh: this.shouldRefresh,
            applyActiveFilter: 0, // decouple teams/teamscoreboard filter from Feedback filter
            fromTeams: 1,
        })

        this.customerLoves = this.tsScorecard
            ? JSON.parse(JSON.stringify(this.tsScorecard.tsgroups))
            : []
        this.focusAreas = JSON.parse(JSON.stringify(this.customerLoves))
        // Do not use this in v-for, will cause infinite loop issue
        this.getYourCustomersLove()
        this.getYourFocusAreas()
        this.loading = false
    }

    private getYourCustomersLove() {
        if (!this.customerLoves) return []
        return this.customerLoves.sort(
            (a, b) => b.respondedPromoter - a.respondedPromoter
        )
    }

    private getYourFocusAreas() {
        if (!this.focusAreas) return []
        return this.focusAreas.sort(
            (a, b) => b.respondedDetractor - a.respondedDetractor
        )
    }

    private totalCustomersLove() {
        return this.customerLoves?.reduce((acc, current) => {
            return acc + current.respondedPromoter
        }, 0)
    }
    private totalFocusAreas() {
        return this.focusAreas?.reduce((acc, current) => {
            return acc + current.respondedDetractor
        }, 0)
    }

    private setDisplay(selection: string) {
        this.displayState = selection
    }

    private asPercentage(num, dem): number {
        let result = 0
        if (num !== undefined && dem !== undefined) {
            result = Math.round(100 * (num / dem))
        }

        return result || 0
    }

    public async showTopicFilteredFeedback(
        topic: TsGroupEntity,
        filterCXType: 'promoters' | 'detractors' | undefined = undefined
    ) {
        if (!this.hasNetworkConnection) {
            alert(OFFLINE_FEATURE_DISABLE_MESSAGE)
            return
        }

        let filterColumn = topic.field
        let filterValue = topic.topicKey
        if (this.$companyVars.has_ai_themes_for_scorecard && topic.group) {
            filterColumn = 'theme'
            filterValue = topic.group
        }

        if (filterValue) {
            const tempFilters = getFiltersForTopicFeedback(
                filterColumn,
                filterValue,
                filterCXType
            )
            // add hierarchy selection to the filters
            tempFilters.push(...this.extraFilters)
            await this.setTopicFeedbackFilters(tempFilters)
        }

        // For Overview, get "scoreDays" data. For Teams, "timeFilter" is applied
        const daysForScoreboard = this.selfOnlyFilter
            ? this.scoreDays
            : this.timeFilterSelected?.value
        const dateRange = getDateRange(Number(daysForScoreboard)).split('/')
        this.setTopicFeedbackTimeOption({
            time_unit: dateRange[0] || null,
            time_value: dateRange[1] || null,
        })

        pendoTrackEvent('teams-topic-clicked')
        await this.router.push({ name: 'topicfeedback' })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.teams-topics {
    display: flex;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;

    .content {
        width: 100%;
        border-radius: @borderRadius-md;
        padding: @featureGutter;
        background-color: @thinFog;
        justify-content: space-between;

        .title-bar {
            display: flex;
            justify-content: space-between;
            margin-bottom: @featureGutter;

            .topic-title,
            .topic-tab {
                color: white;
                letter-spacing: 1px;
            }

            .topic-tab {
                text-align: right;
                text-transform: uppercase;
                transform: translateY(-3px);
            }
        }
    }
}

.count-metric {
    margin: 7px;
    font-size: 12px;
    line-height: 14px;
}
.count-metric-selected {
    padding-bottom: 3px;
    border-bottom: 2px solid white;
}
.dim {
    opacity: 0.6;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0;

    .grid-item {
        padding: 5px 10px 10px 0;
    }

    .topic-item-title {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-transform: capitalize;
    }

    .total {
        padding-top: 0 !important;
        letter-spacing: 1px;
    }
}

.nps-container {
    padding-top: 10px;
    @media (max-width: 400px) {
        padding-top: 20px;
    }
}

.success-green {
    color: @successGreen;
}

.warning-orange {
    color: @warningOrange;
}

.success-green-as-background {
    background: @successGreen;
}

.warning-orange-as-background {
    background: @warningOrange;
}

.background-line {
    background-color: #7e64bf;
    height: 2px;
    width: 100%;
    z-index: 1;
}

.magnitude-line {
    height: 2px;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.topic-title {
    p {
        color: white;
    }
    display: flex;
    gap: 6px;
    align-items: center;
}
</style>
