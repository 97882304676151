<template>
    <div>
        <div
            :class="[
                'vtabs',
                { vsmall, small, large, pills },
                { 'vtab-full-width': fullWidth },
            ]"
        >
            <div
                v-for="(tab, tabIndex) in tabs"
                :key="tabIndex"
                :class="{
                    vtab: true,
                    active: tab.name === active,
                    disabled: tab.disabled,
                    vsmall,
                    small,
                    large,
                    pills,
                    invalid: errorIndices.indexOf(tabIndex) > -1,
                    showArrow,
                    breadcrumbs,
                }"
                @click.self="!tab.disabled && selectTab(tab.name)"
            >
                {{ tab.displayName ? tab.displayName : tab.name }}

                <div
                    v-if="showRemoveTab"
                    :class="['vtab-remove', { inactive: tab.name !== active }]"
                    @click="removeTab(tab.name)"
                >
                    &times;
                </div>
                <img
                    v-if="
                        breadcrumbs && showArrow && tabIndex < tabs.length - 1
                    "
                    class="breadcrumbs-arrow"
                    src="@/assets/img/right-arrow.svg"
                    alt="right arrow"
                />
            </div>

            <div class="action-buttons">
                <slot name="actions"></slot>
            </div>
        </div>

        <div
            ref="tabContainer"
            :class="[
                'vtabs-content',
                { vsmall, small, large, pills, breadcrumbs },
            ]"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { nextTick, useSlots } from 'vue'
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import Tab from './Tab.vue'

@Component({
    emits: ['change', 'remove'],
})
export default class Tabs extends Vue {
    @Prop({ type: String }) public active?: string
    @Prop({ type: Boolean }) public canRemoveTabs?: boolean
    // Theme modifiers
    @Prop({ type: Boolean, default: false }) public vsmall?: boolean
    @Prop({ type: Boolean, default: false }) public small?: boolean
    @Prop({ type: Boolean, default: false }) public large?: boolean
    @Prop({ type: Boolean, default: false }) public pills?: boolean
    @Prop({ type: Array, default: () => [] }) public errorIndices!: number[]
    @Prop({ type: Boolean, default: false }) public showArrow?: boolean
    @Prop({ type: Boolean, default: false }) public breadcrumbs?: boolean
    @Prop({ type: Boolean, default: false }) public fullWidth?: boolean
    @Prop({ type: Boolean, default: false })
    public canRemoveSingleTabs?: boolean

    public tabs: Tab[] = []
    public observer: MutationObserver | null = null

    @Watch('active')
    public onActiveChanged(newActive) {
        nextTick().then(() => {
            this.applyActiveTab(newActive)
        })
    }

    public slots = useSlots()

    public mounted() {
        this.updateTabs()

        const observer = new MutationObserver(this.updateTabs)
        observer.observe(this.$refs.tabContainer as Node, {
            childList: true,
            subtree: true,
        })
        this.observer = observer
        if (this.active) {
            this.applyActiveTab(this.active)
        }
    }
    beforeDestroy() {
        this.observer?.disconnect()
    }

    public updateTabs() {
        if (this.slots.default && Array.isArray(this.slots.default())) {
            let items = this.slots.default()
            let updatedTabs = [] as any
            for (let i = 0; i < Number(items.length); i++) {
                let currentItem = this.slots.default()[i]
                this.tabs = []
                const childrensCount = currentItem['children']?.length ?? 0

                // Must be a Tab component with the prop is-active defined
                if (
                    !childrensCount &&
                    currentItem?.props?.['is-active'] !== undefined
                ) {
                    updatedTabs = [...updatedTabs, currentItem.props]
                }

                for (let j = 0; j < Number(childrensCount); j++) {
                    const currentChild = currentItem['children']?.[j]
                    // Must be a Tab component with the prop is-active defined
                    if (currentChild?.props?.['is-active'] !== undefined) {
                        updatedTabs = [...updatedTabs, currentChild.props]
                    }
                }
            }
            this.tabs = updatedTabs
        }
    }

    public selectTab(tabName: string) {
        this.$emit('change', tabName)
    }

    private removeTab(tabName: string) {
        this.$emit('remove', tabName)
        nextTick(() => {
            this.applyActiveTab(this.active)
        })
    }

    private applyActiveTab(tabName) {
        this.tabs.forEach((tab) => {
            tab.isActive = tab.name === tabName
        })
    }

    private get showRemoveTab() {
        if (this.canRemoveSingleTabs) {
            return true
        }
        return this.canRemoveTabs && this.tabs.length > 1
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';
@import '../styles/layout';
@import '../styles/rain/variables';

.vtabs {
    border-bottom: 1px solid #e4e8f0;
    text-align: left;
    display: flex;
}

.vtabs.pills {
    border-bottom: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.vtabs .action-buttons {
    margin-left: auto;
    margin-right: @gutterSpacing-base;
    align-self: center;
}

.vtabs-content {
    padding: 20px;
}

.vtabs-content.small {
    padding: 20px 0 0 0;
}

.vtabs-content.vsmall {
    padding: 0;
}

.vtabs-content.large {
    padding: 0;
}

.vtabs-content.pills {
    padding-top: 20px;
}

.vtabs-content.breadcrumbs {
    padding: 10px 0 0;
}

.vtab {
    padding: 20px 20px;
    text-align: center;
    text-transform: uppercase;
    color: @textColor;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
    cursor: pointer;

    &:hover {
        color: @ctaColor;
    }

    margin-bottom: -1px;
}

.vtab-full-width .vtab {
    flex: 1;
    padding: 20px 0;
}

.vtab.active {
    border-bottom: 1px solid @ctaColor;
}

.vtab.small {
    padding: 0 0 5px 0;
    margin: 0 10px -1px 10px;
    letter-spacing: 2px;
    color: rgba(46, 91, 255, 0.4);

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.vtab.pills {
    color: #708fff;
    background: #f4f6fc;
    padding: 2px 10px;
    border-radius: 20px;
    border: 1px inset rgba(46, 91, 255, 0.1);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;
    margin-right: 15px;
    letter-spacing: 1px;
    float: left;
    display: flex;
    align-items: center;

    &.invalid {
        border: 1px solid #f13324;
        box-shadow: 0 0 2px #f13324;
    }
}

.vtab.breadcrumbs {
    padding: 10px;
    margin: 0 30px -1px 30px;
    position: relative;

    .breadcrumbs-arrow {
        position: absolute;
        right: -35px;
        top: 12px;
        cursor: default;
    }

    &.active {
        border-bottom: 1px solid @ctaColor;
        color: @ctaColor;
    }
}

.vtab-remove {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 14px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -3px;

    &.inactive {
        pointer-events: none;
    }

    &:hover {
        background-color: #545ebb;
        color: #fff;
    }
}

.vtab.vsmall.pills {
    font-size: 11px;
    margin-right: 8px;
    margin-bottom: 6px;
    padding: 0 8px;
}

.vtab.pills.active {
    color: #fff;
    background: #708fff;
}

.vtab.small.active {
    color: @ctaColor;
}

.vtab,
.vtab.small,
.vtab.pills,
.vtab.active,
.vtab.small.active,
.vtab.breadcrumbs.active,
.vtab:hover {
    &.disabled {
        color: @greyLight4;
        cursor: default;
    }
}
</style>
