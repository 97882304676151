<template>
    <transition v-if="showReportCard" name="slide-fade" appear>
        <div class="report-card-popup">
            <ReportCard
                :report-card-user="reportCardUser"
                :month="Number(reportMonth)"
                :close-popup="closePopup"
                @monthChanged="monthChanged"
            />
        </div>
    </transition>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import ReportCard from '@/mobile/src/views/ReportCard.vue'
import { Getter, namespace } from 'vuex-facing-decorator'
import { getLastMonthUsingTimezone } from '@/utils/time'

const ReportCardModule = namespace('reportcard')

@Component({
    components: {
        ReportCard,
    },
})
export default class ReportCardPopup extends Vue {
    @ReportCardModule.Getter showReportCard
    @ReportCardModule.Getter reportCardData
    @ReportCardModule.Getter drillDownUser
    @ReportCardModule.Getter reportCardUser
    @ReportCardModule.Action setShowReportCard
    @ReportCardModule.Action setShowTeamProfileSelect
    @ReportCardModule.Action loadReportCardData

    @Getter $companyVars

    @Watch('showReportCard')
    public async onShowReportCard() {
        if (this.showReportCard) {
            await this.loadReportCardData()
        }
    }

    private month = new Date().getMonth() + 1

    public get reportMonth() {
        return getLastMonthUsingTimezone(this.$companyVars.timezone)
    }

    public monthChanged(month): void {
        this.month = month
    }

    public closePopup() {
        this.setShowReportCard(false)
        this.setShowTeamProfileSelect(false)
    }
}
</script>

<style scoped lang="less">
@import '~@/mobile/src/styles/zindex.less';

.report-card-popup {
    width: 100%;
    height: 100%;
    top: calc(105px + env(safe-area-inset-top));
    position: fixed;
    left: 0;
    z-index: @reportCardIndex;
}

.slide-fade-enter-from {
    transform: translateY(700px);
}

.slide-fade-enter-active {
    animation: slide 0.3s backwards;
    transition: 0.3s;
}

.slide-fade-leave-active {
    animation: slide 0.5s forwards;
    transition: 0.5s;
}

.slide-fade-leave-to {
    transform: translateY(700px);
}
</style>
