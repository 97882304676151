<template>
    <div>
        <LoadingCard v-if="loadingWorkflow" variant="single" borderless />
        <div v-else class="feedback-workflow-container">
            <div class="feedback-workflow-content">
                <ActionButton
                    v-for="chatWorkflow in chatWorkflows"
                    :key="chatWorkflow.id"
                    :on-click="
                        goWorkflow.bind(
                            this,
                            Number(chatWorkflow.id),
                            chatWorkflow.name
                        )
                    "
                    :label="chatWorkflow.name"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { Getter } from 'vuex-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { getWorkflows, runWorkflow } from '@/api/chat'
import { ChatWorkflow } from '@/entities'
import { ToastColor, ToastDuration, ToastIcon } from '@/mobile/src/types/toasts'
import { showToast, hideToast } from '@/mobile/src/utils/toastbus'
import { sleep } from '@/utils/async'
import ActionButton from '@/mobile/src/components/appV4/ActionButton.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'

@Component({
    components: {
        LoadingCard,
        ActionButton,
    },
})
export default class FeedbackWorkflow extends Vue {
    @Getter public chatCustomFields

    private route = useRoute()
    private router = useRouter()
    private emitter = useEmitter()

    private chatWorkflows: {
        [chatWorkflowId: string]: ChatWorkflow
    } = {}
    public loadingWorkflow = false

    async mounted(): Promise<void> {
        await this.loadWorkflows()
    }

    private async goWorkflow(
        workflowId: number,
        workflowName: string
    ): Promise<void> {
        if (
            confirm(`Are you sure you want to run "${workflowName}" workflow?`)
        ) {
            const loadingToast = showToast(
                this.emitter,
                ToastIcon.Waiting,
                ToastColor.Workflow,
                `Scheduling Workflow`
            )
            const { data } = await runWorkflow(
                workflowId,
                this.route.params.feedbackid ?? '',
                true
            )
            hideToast(this.emitter, loadingToast)

            if (data.success) {
                showToast(
                    this.emitter,
                    ToastIcon.Success,
                    ToastColor.Workflow,
                    `Workflow scheduled to run`,
                    ToastDuration.Standard
                )
                await sleep(ToastDuration.Standard)
                this.router.back()
            } else {
                showToast(
                    this.emitter,
                    ToastIcon.Alert,
                    ToastColor.Workflow,
                    'Failed to run workflow',
                    ToastDuration.Standard
                )
            }
        }
    }

    private async loadWorkflows(): Promise<void> {
        this.loadingWorkflow = true
        const result = await getWorkflows()
        this.chatWorkflows = result.data
        this.loadingWorkflow = false
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/palette.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/variables.less';

.feedback-workflow-container {
    .back {
        color: white;
    }

    .feedback-workflow-content {
        width: 100%;
        position: relative;
        left: -@featureGutter;
    }
}
</style>
