<template>
    <div>
        <FeedbackActionsBar
            v-if="hasNetworkConnection"
            :feedback-id="Number(feedbackid)"
            @case-updated="onMessageSent"
        />
        <ItemDetail :feedbackid="feedbackid" @message-sent="onMessageSent">
            <template #detailContent="{ undoChat: onUndoChat }">
                <LoadingCard
                    v-if="isLoading"
                    :borderless="true"
                    variant="single"
                />
                <div
                    v-for="(chat, index) in visibleChatMessages"
                    v-else
                    :key="index"
                    :class="{ 'last-card': isLastCard(index) }"
                >
                    <FeedbackResponseCard
                        v-if="chat.type === 'question'"
                        :key="'frc' + chat.id"
                        detailed-view
                        :response="chat"
                        :custom-data-set="customDataSet"
                        :first-ai-question="firstAiQuestion"
                        :custom-data="chatCustomData"
                        :hide-topic="
                            Boolean($companyVars.has_ai_themes_for_scorecard)
                        "
                        @toggleStarred="onToggleStarred"
                    />
                    <MomentCard
                        v-else
                        :key="'mc' + chat.id"
                        :ref="chatSeenRef(chat)"
                        :emoji-view="showEmoji(chat)"
                        detailed-view
                        disable-highlight
                        :moment="chat"
                        @onReact="onReact"
                        @undoChat="onUndoChat"
                        @reloadChat="reloadChat"
                        @hardReloadChat="hardReloadChat"
                    />
                </div>
            </template>
        </ItemDetail>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import useEmitter from '@/composables/useEmitter'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import ItemDetail from '@/mobile/src/views/appV4/ItemDetail.vue'
import FeedbackResponseCard from '@/mobile/src/components/appV4/feedback/FeedbackResponseCard.vue'
import MomentCard from '@/mobile/src/components/appV4/MomentCard.vue'
import {
    Dict,
    FeedbackDetailMessage,
    IEmojiReactionUpdateData,
    IOnReact,
    IResponse,
} from '@/entities'
import { ChatReplyState } from '@/entities/chatresponse'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { scrollToBottom } from '@/mobile/src/utils/scrollbus'
import { updateEmojiReactionDetails } from '@/mobile/src/utils/emojireactions'
import { updateEmoji } from '@/api/chat'
import { MessageTypeLabel } from '@/mobile/src/types/messageTypes'
import { Permissions } from '@/mobile/src/types/auth'
import FeedbackActionsBar from '@/mobile/src/components/appV4/feedback/FeedbackActionsBar.vue'
import { LocationQueryValue, useRoute } from 'vue-router'

const MomentsModule = namespace('moments')

@Component({
    components: {
        FeedbackActionsBar,
        LoadingCard,
        MomentCard,
        FeedbackResponseCard,
        ItemDetail,
    },
})
export default class FeedbackDetail extends Vue {
    @Prop({ type: String, default: null }) feedbackid?: string

    @Action loadChat!: () => Promise<void>

    @Action public toggleDetailResponseStar
    @Action public setChatQuestion!: (
        id: number,
        forceReload: boolean
    ) => Promise<void>

    @Getter public $companyVars
    @Getter public mobileFilterActive
    @Getter public permissions
    @Getter public hasNetworkConnection!: boolean
    @Getter public responsesMap!: Dict<IResponse[]>

    @Getter public chatMessages!: FeedbackDetailMessage[]
    @Getter public chatCustomFields
    @Getter public chatConversationalData
    @Getter public chatReply!: ChatReplyState
    @Getter public chatQuestionId!: number
    @Getter public chatCustomData

    @MomentsModule.Action resetComposer

    private route = useRoute()

    private customDataSet = {}
    private firstAiQuestion = {}
    public isLoading = true
    public loadingCancelled = false
    public scrollTimer!: NodeJS.Timeout

    public emitter = useEmitter()

    // Random string to trigger loading new items from push notification clicked
    public refreshKey: string | LocationQueryValue[] = ''

    async mounted() {
        await this.reloadChat()

        this.isLoading = false
        if (!this.loadingCancelled && this.chatMessages.length > 1) {
            this.scrollTimer = setTimeout(this.scrollToUnread, 200)
        }

        if (Object.keys(this.customDataSet).length === 0) {
            const { ai_question_1, ...remainingData } =
                this.chatConversationalData || []
            this.firstAiQuestion = ai_question_1

            const shouldExcludeFirstQuestion =
                ai_question_1 &&
                this.chatCustomData?.comment === ai_question_1?.value

            this.customDataSet = shouldExcludeFirstQuestion
                ? remainingData
                : this.chatConversationalData
        }
    }

    async beforeDestroy() {
        this.loadingCancelled = true
        clearTimeout(this.scrollTimer)
        await this.resetComposer() // Reset message composer so other feedback details won't be retained, especially when making an email reply
    }

    protected async reloadChat() {
        this.isLoading = true

        const routeId = Number(this.feedbackid)

        if (this.chatQuestionId !== routeId) {
            await this.setChatQuestion(routeId, true)
        } else {
            await this.hardReloadChat()
        }

        this.isLoading = false
    }

    protected async hardReloadChat() {
        this.isLoading = true

        await this.loadChat()

        this.isLoading = false
    }

    @Watch('route.query')
    public async onRouteQueryUpdated() {
        if (
            this.route.query.fromPushNotification &&
            this.route.query.fromPushNotification !== this.refreshKey &&
            this.chatMessages.length > 0
        ) {
            this.refreshKey = this.route.query.fromPushNotification
            await this.hardReloadChat()
            this.scrollToUnread()
        }
    }

    public get key() {
        return JSON.stringify(this.mobileFilterActive)
    }

    public get visibleChatMessages() {
        return this.chatMessages.filter((chatMessage) => {
            if (
                chatMessage.type === 'Internal Note' &&
                !this.permissions?.includes(Permissions.RoleViewNotes)
            ) {
                return false
            }

            return true
        }, this)
    }

    public async onToggleStarred(data) {
        await this.toggleDetailResponseStar({
            questionId: Number(data.questionId),
        })
    }

    public onMessageSent() {
        this.loadChat().then(() => this.scrollToLastCard())
    }

    private scrollToLastCard() {
        const lastCard = document
            .getElementsByClassName('last-card')
            .item(0) as HTMLDivElement
        if (lastCard) {
            lastCard.scrollIntoView({ behavior: 'smooth' })
        }
    }

    public scrollToUnread() {
        const lastUnreadChat = this.$refs.firstNotSeen
        if (
            lastUnreadChat &&
            Array.isArray(lastUnreadChat) &&
            lastUnreadChat.length
        ) {
            // Avoiding multitype complaints
            (lastUnreadChat[0] as any).$el.scrollIntoView({
                behavior: 'smooth',
            })
        } else {
            scrollToBottom(this.emitter, 'smooth')
        }
    }

    public chatSeenRef(chat) {
        return chat.firstNotSeen ? 'firstNotSeen' : 'chat'
    }

    public isLastCard(index: number): boolean {
        return (
            this.chatMessages?.length > 1 &&
            this.chatMessages.length - 1 === index
        )
    }

    private showEmoji(chat): boolean {
        return (
            [
                MessageTypeLabel.internalNote,
                MessageTypeLabel.emailReply,
                MessageTypeLabel.workflow,
            ].includes(chat.type) && this.hasNetworkConnection
        )
    }

    private async onReact(data: IOnReact) {
        if (data.source && data.sourceId) {
            const updateData: IEmojiReactionUpdateData = {
                emoji: data.emoji,
                emojis: data.emojis,
            }

            try {
                updateEmojiReactionDetails(updateData)
                await updateEmoji(data.source, data.sourceId, data.emoji)
            } catch (e) {
                // Undo the count and self flag change.
                // For some reason, anything that isn't status 200 is thrown.
                updateEmojiReactionDetails(updateData)
            }
        }
    }
}
</script>

<style lang="less" scoped></style>
