import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['page-background', { gradient: _ctx.secondaryBgColour }]),
    style: _normalizeStyle(_ctx.bgColourStyles),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.containerClick && _ctx.containerClick(...args)), ["stop"]))
  }, [
    _createElementVNode("div", {
      id: "page-content",
      ref: "pagecontent",
      class: _normalizeClass([
                {
                    'stop-scroll': _ctx.stopScroll || _ctx.haxIosDisableContentScrolling,
                    'no-nav-pad': _ctx.noNavPad,
                },
                `${_ctx.navBarPosition}-nav`,
            ])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 6))
}