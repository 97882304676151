<template>
    <div v-if="topics.length" class="huddles-topics-chart">
        <div class="goal--tab">Base goal {{ baseCxscoreGoal }}%</div>
        <div class="goal--line" />

        <div class="topics-list">
            <div v-for="(topic, index) in topics" :key="index" class="topic">
                <div class="labels">
                    <Typography variant="caption" class="label--topic">
                        {{ topic.topic }}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        :class="[
                            'label--cxscore',
                            { positive: baseCxscoreGoal < topic.cxscore },
                        ]"
                    >
                        {{ topic.cxscore }}
                        <FontAwesomeIcon
                            :icon="arrowIcon(topic.cxscore)"
                            size="2xs"
                        />
                    </Typography>
                </div>

                <div class="bar">
                    <div
                        :class="[
                            'bar bar--overlay',
                            { positive: baseCxscoreGoal < topic.cxscore },
                        ]"
                        :style="{
                            width: `${getBarWidthPercentage(topic.cxscore)}%`,
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Topic } from '@/entities/huddles'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faArrowDown, faArrowUp } from 'fontawesome/free-solid-svg-icons'

interface Props {
    topics: Topic[]
    baseCxscoreGoal: number | undefined
}
const props = defineProps<Props>()

const arrowIcon = (cxscore: number) => {
    return props.baseCxscoreGoal <= cxscore ? faArrowUp : faArrowDown
}

const getBarWidthPercentage = (cxscore: number): number => {
    const goalLinePosition = 66 // It is positioned at 66% of the width of it's container

    if (cxscore > props.baseCxscoreGoal) {
        // CX score above goal: Calculate percentage relative to space above goal
        const excessCxscore = cxscore - props.baseCxscoreGoal
        return (
            (excessCxscore / (100 - props.baseCxscoreGoal)) *
            (100 - goalLinePosition)
        )
    } else if (cxscore < props.baseCxscoreGoal) {
        // CX score below goal: Calculate percentage relative to space below goal
        const belowCxscore = props.baseCxscoreGoal - cxscore
        return (belowCxscore / props.baseCxscoreGoal) * goalLinePosition
    }
    return 0
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/prime-vue/variables.less';

@goalLinePosition: 66%;

.huddles-topics-chart {
    position: relative;
}

.goal {
    &--tab {
        color: white;
        background: @greenLegible;
        padding: 5px 10px;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        width: 80px;
        position: absolute;
        left: calc(@goalLinePosition - 11%);
        z-index: 2;
    }

    &--line {
        width: 0;
        height: 100%;
        position: absolute;
        border-left: 3px dashed @greenLegible;
        left: @goalLinePosition;
    }
}

.topics-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0;
    padding-top: 36px;

    .topic {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .labels {
            display: flex;
            justify-content: space-between;

            .label {
                &--topic {
                    color: var(--p-gray-600);
                }
                &--cxscore {
                    color: var(--p-gray-400);

                    &.positive {
                        color: @greenLegible;
                    }
                }
            }
        }

        .bar {
            height: 4px;
            width: 100%;
            border-radius: 24px;
            background-color: var(--p-gray-200);

            &--overlay {
                width: 12%;
                position: absolute;
                right: calc(100% - @goalLinePosition);
                background: var(--p-gray-400);
                border-radius: @radius-2xl 0 0 @radius-2xl;

                &.positive {
                    background: @greenLegible;
                    left: @goalLinePosition;
                    border-radius: 0 @radius-2xl @radius-2xl 0;
                }
            }
        }
    }
}
</style>
