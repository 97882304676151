<template>
    <FeatureContainer :class="['feature-container']">
        <div class="topic-header" @click="showTopicFilteredFeedback()">
            <div>
                <Typography class="text topic-subtitle" variant="overline">{{
                    topicSubtitle
                }}</Typography>
                <Typography
                    class="text topic-label"
                    variant="h4"
                    component="h4"
                    >{{ topicItem.label }}</Typography>
            </div>
            <div class="response-percentage">
                <Typography class="text uppercase" variant="overline">{{ topicItem.percentage }}% of responses</Typography>
            </div>
        </div>

        <div
            v-if="topicItem && topicItem.responses.length > 0"
            class="carousel-container"
        >
            <Carousel>
                <div
                    v-for="(response, index) in getResponses()"
                    :key="index"
                    class="response-container"
                >
                    <ResponseCircle
                        class="score-circle"
                        :score="response.score"
                        :variation="response.type"
                        @click="navigateToFeedback(response.id)"
                    />
                    <div class="response-content">
                        <div class="response-info top">
                            <span
                                class="response-text"
                                @click="navigateToFeedback(response.id)"
                            >
                                <Typography
                                    :class="[
                                        'text',
                                        {
                                            'italic-text':
                                                isNoCommentResponse(response),
                                        },
                                    ]"
                                    variant="body2"
                                >
                                    {{ getResponseComment(response) }}
                                </Typography>
                            </span>
                        </div>
                        <div
                            class="response-info"
                            @click="navigateToFeedback(response.id)"
                        >
                            <Typography
                                class="text uppercase transparent author"
                                variant="overline"
                                >{{ response.name }}</Typography>
                            <Typography
                                class="text uppercase transparent"
                                variant="overline"
                                >{{
                                    getRelativeDate(response.responded)
                                }}</Typography>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    </FeatureContainer>
</template>

<script lang="ts">
import { Getter, Action, namespace } from 'vuex-facing-decorator'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import Typography from '@/components/Rain/Typography/Typography.vue'
import ResponseCircle from '@/components/ResponseCircle.vue'
import Carousel from '@/components/Rain/Layouts/Carousel/Carousel.vue'
import FeatureContainer from '@/mobile/src/components/appV4/FeatureContainer.vue'
import { TopicFeedback } from '@/entities/topicfeedback'
import { TopicResponse } from '@/entities/topicresponse'
import { relativeDate } from '@/utils/time'
import { pendoTrackEvent } from '@/utils/pendo'
import { getFiltersForTopicFeedback } from '@/mobile/src/utils/topics'
import { noCommentResponse } from '@/entities'
import { OFFLINE_FEATURE_DISABLE_MESSAGE } from '@/utils/string'
import { getDateRange } from '@/utils/scorecard'
import { IFilterOption } from '@/entities/teamFilter'

const TimeFilterModule = namespace('timeFilter')

@Component({
    components: {
        FeatureContainer,
        Carousel,
        ResponseCircle,
        Typography,
        FontAwesomeIcon,
    },
})
export default class TopicFeedbackItem extends Vue {
    @Prop({ type: Object as () => TopicFeedback, required: true })
    readonly topicItem!: TopicFeedback | null
    @Prop({ type: Boolean, required: false, default: false }) isReportCard

    @Getter hasNetworkConnection!: boolean
    @Getter public $companyVars
    @TimeFilterModule.Getter timeFilterSelected?: IFilterOption
    @Action public setTopicFeedbackFilters
    @Action public setTopicFeedbackTimeOption

    private router = useRouter()

    public getResponses(): TopicResponse[] {
        // sort by star if on the reportcard page, otherwise default order.
        if (this.isReportCard) {
            let responses = this.topicItem ? [...this.topicItem.responses] : []
            return responses.sort((a, b) => {
                if (a.isStarred && !b.isStarred) {
                    return -1
                } else if (!a.isStarred && b.isStarred) {
                    return 1
                } else {
                    return responses.indexOf(a) - responses.indexOf(b)
                }
            })
        } else {
            return this.topicItem?.responses ?? []
        }
    }

    public get topicSubtitle(): string {
        if (
            this.topicItem &&
            'isFirstTopic' in this.topicItem &&
            this.topicItem.isFirstTopic
        ) {
            return 'Your customers love'
        }
        if (
            this.topicItem &&
            'isLastTopic' in this.topicItem &&
            this.topicItem.isLastTopic
        ) {
            return 'Your focus area'
        }
        return 'Topic'
    }

    public getResponseComment(response: TopicResponse) {
        return response.comment && response.comment.trim()
            ? response.comment
            : noCommentResponse
    }

    public isNoCommentResponse(response: TopicResponse) {
        return this.getResponseComment(response) === noCommentResponse
    }

    private getRelativeDate(responded) {
        return relativeDate(parseInt(responded))
    }

    navigateToFeedback(responseId: number) {
        if (!this.hasNetworkConnection) {
            alert(OFFLINE_FEATURE_DISABLE_MESSAGE)
            return
        }
        if (!this.isReportCard) {
            this.router.push({
                name: 'feedbackdetail',
                params: { feedbackid: responseId.toString() },
            })
        }
    }

    public async showTopicFilteredFeedback() {
        if (!this.hasNetworkConnection) {
            alert(OFFLINE_FEATURE_DISABLE_MESSAGE)
            return
        }

        if (!this.topicItem || !this.topicItem?.scorecardField) {
            return
        }

        let filterColumn = this.topicItem.scorecardField
        let filterValue = this.topicItem.key
        if (
            this.$companyVars.has_ai_themes_for_scorecard &&
            this.topicItem.topicName
        ) {
            filterColumn = 'theme'
            filterValue = this.topicItem.topicName
        }

        const tempFilters = getFiltersForTopicFeedback(
            filterColumn,
            filterValue
        )

        await this.setTopicFeedbackFilters(tempFilters)
        // Apply Overview page time filter to topic feedback list.
        if (this.timeFilterSelected) {
            const dateRange = getDateRange(
                Number(this.timeFilterSelected?.value)
            ).split('/')
            await this.setTopicFeedbackTimeOption({
                time_unit: dateRange[0] || null,
                time_value: dateRange[1] || null,
            })
        }

        pendoTrackEvent('topic-feedback-header-clicked')
        await this.router.push({ name: 'topicfeedback' })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.text {
    color: white;
}

.uppercase {
    text-transform: uppercase;
}

.transparent {
    opacity: 0.6;
}

.topic-subtitle {
    padding-bottom: 6px;
    font-weight: 400;
}

.topic-label {
    line-height: 22px;
}

.author {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.topic-header {
    padding-bottom: @featureGutter;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.response-percentage {
    max-width: 110px;
    text-align: right;

    .text {
        font-weight: 400;
    }
}

.carousel-container {
    border-top: 1px solid @thinFog;
}

.response-container {
    padding-top: @featureGutter;
    display: flex;

    .score-circle {
        min-width: 38px;
        height: 38px;
    }
}

.response-content {
    padding-left: 12px;
    width: 100%;
}

.response-info {
    display: flex;
    justify-content: space-between;

    &.top {
        padding-bottom: 15px;
    }

    .response-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 42px; /* fallback if webpack is not supported */
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        flex-grow: 3;
        .italic-text {
            font-style: italic;
        }
    }
}

.star-container {
    position: relative;

    .star {
        color: white;
        height: 18px;
        width: 20px;
        margin-left: 15px;
        padding-top: 4px;

        &.solid {
            // Overlay the solid star on top of outlined star
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
        }

        &.show {
            opacity: 1;
        }
    }
}
</style>

<style lang="less">
@import '~@/styles/rain/variables.less';

.carousel-container {
    .an-carousel-dots {
        padding-top: @featureGutter;
    }
}
</style>
