<template>
    <div class="btn-container">
        <button
            v-if="isNativePlatform"
            class="btn-google-auth"
            @click="doNativeGoogleSignIn"
        >
            <img
                class="icon"
                :class="{ 'not-loading': !loading }"
                src="@/assets/img/logo/google_logo.png"
                alt="Google"
            />
            <template v-if="!loading"> Login with Google </template>
            <template v-else>
                <div class="spinner-container">
                    <SimpleSpinner
                        size="small"
                        class="spinner"
                        line-fg-color="transparent"
                    />
                </div>
            </template>
        </button>
        <div v-else id="web-mobile-google-sign-in-button"></div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { googleSignIn, initWebGoogleSignIn } from '@/mobile/src/utils/capacitor'
import { loginWithGoogleIdToken } from '@/mobile/src/api/auth'
import SimpleSpinner from '@/components/SimpleSpinner.vue'

const emit = defineEmits(['signin'])
const store = useStore()

const loading = ref(false)

const isNativePlatform = computed(
    () => store.getters['deviceInfo']?.platform !== 'web' ?? true
)

onMounted(() => {
    if (!isNativePlatform.value) {
        initWebGoogleSignIn((googleUserAuthId: string) =>
            doWebGoogleSignIn(googleUserAuthId)
        )
    }
})

const doWebGoogleSignIn = async (googleUserAuthId: string) => {
    const data = await loginWithGoogleIdToken(googleUserAuthId)
    if (data) {
        await emit('signin', data)
    }
}

const doNativeGoogleSignIn = async () => {
    if (loading.value) {
        return
    }
    loading.value = true
    let data
    const result = await googleSignIn()

    if (result?.success && result?.googleUserAuthId) {
        data = await loginWithGoogleIdToken(result.googleUserAuthId)
    } else {
        data = result
    }
    emit('signin', data)
    loading.value = false
}
</script>

<style scoped lang="less">
.btn-container {
    display: inline-block;

    .btn-google-auth {
        background: white;
        border: none;
        line-height: 28px;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        font-size: 14px;
        width: 170px;
        height: 40px;

        .icon {
            width: 15px;

            &.not-loading {
                margin-right: 15px;
            }
        }

        .spinner-container {
            text-align: center;
            flex: 1;
        }
    }
}
</style>
