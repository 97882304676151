<template>
    <div class="web-view-screen" :style="style">
        <div v-if="showGoBack" class="back" @click="goBack">
            <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.39966 6.99636L7.66805 1.70469C8.05769 1.31302 8.05769 0.67969 7.66805 0.29219C7.27842 -0.0994771 6.64836 -0.0953104 6.25873 0.29219L0.289815 6.28802C-0.0873869 6.66719 -0.0956771 7.27552 0.2608 7.66719L6.25458 13.7047C6.4494 13.9005 6.7064 13.9964 6.95925 13.9964C7.21209 13.9964 7.46909 13.9005 7.66391 13.7047C8.05355 13.313 8.05355 12.6797 7.66391 12.2922L2.39966 6.99636Z"
                    :fill="`black`"
                />
            </svg>
        </div>

        <div v-if="!hideHeader" class="header">
            <WebViewHeader :title="title" :status-bar-height="statusBarHeight">
                <template #left-button><slot name="left-button"></slot></template>
                <template #custom-title><slot name="custom-title"></slot></template>
                <!-- hide right button when showing the offline screen -->
                <template #right-button>
                    <slot v-if="!showOfflineScreen" name="right-button"></slot>
                </template>
            </WebViewHeader>
        </div>

        <transition name="slide">
            <OfflineBanner v-if="!hasNetworkConnection" />
        </transition>

        <div :class="['content', { 'no-pad': noPad }]">
            <OfflineScreen v-if="showOfflineScreen" />
            <slot v-else></slot>
        </div>

        <div class="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import WebViewHeader from './WebViewHeader.vue'
import { embedded } from '@/utils/react-native-webview'
import { Action, Getter } from 'vuex-facing-decorator'
import OfflineBanner from '@/mobile/src/components/OfflineBanner.vue'
import OfflineScreen from '@/mobile/src/views/OfflineScreen.vue'

/**
 * This is a wrapper for scaffolding web view screens in a consistent way
 */

@Component({
    components: {
        OfflineScreen,
        OfflineBanner,
        WebViewHeader,
    },
})
export default class WebViewScreen extends Vue {
    @Prop({ type: String, default: '' }) public title!: string
    @Prop({ type: Boolean, default: false }) public hideHeader!: boolean
    @Prop({ type: Boolean, default: false }) public noPad!: boolean
    @Prop({ type: Boolean, default: false }) public showGoBack!: string
    @Prop({ type: Boolean, default: false }) public avoidKeyboard!: boolean
    @Prop({ type: Boolean, default: false }) public disableWhenOffline!: boolean

    @Action public setSlide

    @Getter
    hasNetworkConnection!: boolean

    private embedded: boolean = embedded
    private statusBarHeight = 0
    private screenHeight = '100%'

    private router = useRouter()

    public get style() {
        return {
            height: this.screenHeight,
        }
    }

    public get showOfflineScreen() {
        return this.disableWhenOffline && !this.hasNetworkConnection
    }

    public mounted() {
        if (this.avoidKeyboard) {
            window.addEventListener('message', this.onMessageReceived)
        }
        if (embedded) {
            this.setHeight()
        }
    }

    public setHeight() {
        setTimeout(this.setHeight, 250)
    }

    private beforeDestroy() {
        if (this.avoidKeyboard) {
            window.removeEventListener('message', this.onMessageReceived)
        }
    }

    private goBack() {
        this.router.back()
    }

    // adjust screen height
    private onMessageReceived(message) {
        if (!message || !message.data || typeof message.data !== 'string') {
            return // vue dev tools seems sending invalid data to this function
        }
        const { event, value } = JSON.parse(message.data)
        if (event === 'keyboardDidShow') {
            this.screenHeight = `calc(100vh - ${value + 20}px)`
        }
        if (event === 'keyboardDidHide') {
            this.screenHeight = '100%'
        }
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette';

.web-view-screen {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;

    .back {
        position: absolute;
        cursor: pointer;
        padding: 18px;
        width: 25px;

        svg {
            min-width: 8px;
        }
    }
}

.header {
    flex: 0 1;
}

.content {
    overflow-y: auto; // so content can scroll
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-flow: column;
    flex: 1;

    &.no-pad {
        padding: 0;
    }
}

.footer {
    flex: 0 1;
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
    max-height: 50px;
    overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
