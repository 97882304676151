import { ILeaderboardResult } from '@/entities/leaderboard'

import AchievementCakeday from '@/assets/img/scorecard/achievements/cakeday.svg'
import AchievementAllfiredup from '@/assets/img/scorecard/achievements/allfiredup.svg'
import AchievementAsktronaut from '@/assets/img/scorecard/achievements/asktronaut.svg'
import AchievementUnknownBadge from '@/assets/img/scorecard/achievements/unknown-badge.svg'
import AchievementLogin from '@/assets/img/scorecard/task_badges/login.svg'
import AchievementCheckIn from '@/assets/img/scorecard/task_badges/daily-check-in.svg'
import AchievementSuggestion from '@/assets/img/scorecard/task_badges/send-suggestion.svg'

import BadgeCommunication from '@/assets/img/scorecard/badges/great_communication.svg'
import BadgeHacker from '@/assets/img/scorecard/badges/hacker.svg'
import BadgeRocket from '@/assets/img/scorecard/badges/rocket.svg'
import BadgeSkill from '@/assets/img/scorecard/badges/skilled_service.svg'
import BadgeFriendliness from '@/assets/img/scorecard/badges/super_friendly.svg'
import BadgeEmpathy from '@/assets/img/scorecard/badges/sympathetic_and_kind.svg'
import BadgeTreatment from '@/assets/img/scorecard/badges/treatment.svg'
import BadgeKnowledge from '@/assets/img/scorecard/badges/wealth_of_knowledge.svg'
import BadgeEfficiency from '@/assets/img/scorecard/badges/well_organized.svg'

export interface Badge {
    comments: string[]
    badgeId?: string
    value: string
    count: number
    countCalendarMonth?: number
    ids: string[]
    countUnseen: number
    idsUnseen: []
    label: string
    type: string
    percentage: number
    tokens?: AchievementToken[]
    topicName?: string
}

export interface AchievementToken {
    title: string
    description: string
}

export interface Badges {
    [badge: string]: Badge
}

export interface ScorecardEntity {
    leaderboard: string
    change: number
    month: string // current month name
    name: string // the actual value
    names: string[] // all values if there are multiple locked filter values
    scorecardField: string // the field storing scorecard
    nps: number
    previous: number
    previousResponded: number
    rank: number
    rankValues: number[]
    ranks: ILeaderboardResult[]
    rankTotal: number // total number of ranks
    rankLimitNumber: number
    rankFieldLabel: string // label displayed in navigation bar
    rankEnabled: number
    rankDown: number
    rankUp: number
    responded: number
    avatars: {
        [value: string]: string
    }
    user: {
        id: number
        name: string
        avatar: string
        frontline_manager: boolean
    }
    badges: {
        negative: Badges
        positive: Badges
    }
    achievements?: Badges
    selectedBadge: Badge | null
    last_updated: number
    isFiveScoreQuestionType: boolean
    questionType: string
    status: string // status of scorecard, gold/silver/bronze
}

export interface BadgeSchema {
    backColor: string
    foreColor: string
    color: string
    label: string
    img: string
    title?: string // To show in the complete pop-up
    description?: string // To show in the complete pop-up
    animationData?: string
    rules?: string // rules to gain the badge
}

export interface BadgeSchemas {
    [badge: string]: BadgeSchema
}

// Make sure these names match the ones defined in lib/Achievement/AchievementService.php
export const achievementSchemas: BadgeSchemas = {
    cakeday: {
        backColor: '#FEF2F7',
        foreColor: '#2E5BFF',
        color: 'white',
        label: 'Cake Day',
        img: AchievementCakeday,
        description:
            'Welcome to the AskNicely family! We’re so happy to have you, so here’s some cake! 🎊🎂',
        animationData: '/img/scorecard/animated/cakeday.json',
        rules: 'You’ll get a Cake Day achievement on the anniversary of your AskNicely account',
    },
    allfiredup: {
        backColor: '#FEF2F7',
        foreColor: '#EE2779',
        color: 'white',
        label: 'All Fired Up',
        img: AchievementAllfiredup,
        description:
            'Nice! You’ve checked out feedback on AskNicely 3 days in a row! You’re on FIRE 🔥!',
        animationData: '/img/scorecard/animated/allfiredup.json',
        rules: 'You’ll get an All Fired Up achievement for 3 days of consecutive use',
    },
    asktronaut: {
        backColor: '#FEF2F7',
        foreColor: '#060A7F',
        color: 'white',
        label: 'Ask-tronaut',
        img: AchievementAsktronaut,
        description:
            'Wow! You’re the most active AskNicely user this week! 💯🙌',
        animationData: '/img/scorecard/animated/asktronaut.json',
        rules: 'You’ll get an Asktronaut achievement for having the highest AskNicely usage in your company',
    },
    login: {
        backColor: '#FEF2F7',
        foreColor: '#060A7F',
        color: 'white',
        label: 'Login',
        img: AchievementLogin,
        title: 'Woohoo!',
        description: `Welcome back to AskNicely! Consistency is key and you’re nailing it.`,
    },
    checkin: {
        backColor: '#FEF2F7',
        foreColor: '#060A7F',
        color: 'white',
        label: 'Daily Check-In',
        img: AchievementCheckIn,
        title: 'Way to Go!',
        description:
            'Regular check-ins help your team improve. Thanks for the input!',
    },
    suggestion: {
        backColor: '#FEF2F7',
        foreColor: '#060A7F',
        color: 'white',
        label: 'Suggestion',
        img: AchievementSuggestion,
        title: 'Nice One!',
        description:
            'Awesome to see that you’ve made suggestion to your company this month! Your colleagues can respond and let you know what they think! Keep up the good work!',
    },
}

export const achievementUnknownBadgeImage = AchievementUnknownBadge

export const badgeSchemas: BadgeSchemas = {
    communication: {
        backColor: '#FEF2F7',
        foreColor: '#EE2779',
        color: 'white',
        label: 'Great Communication',
        img: BadgeCommunication,
        animationData: '/img/scorecard/animated/communication.json',
    },
    knowledge: {
        backColor: '#FEF9EB',
        foreColor: '#F7C137',
        color: 'black',
        label: 'Wealth of Knowledge',
        img: BadgeKnowledge,
        animationData: '/img/scorecard/animated/knowledge.json',
    },
    empathy: {
        backColor: '#F3FEF9',
        foreColor: '#20E694',
        color: 'white',
        label: 'Sympathetic & Kind',
        img: BadgeEmpathy,
        animationData: '/img/scorecard/animated/empathy.json',
    },
    friendliness: {
        backColor: '#FFF7F4',
        foreColor: '#FB7E42',
        color: 'white',
        label: 'Super Friendly',
        img: BadgeFriendliness,
        animationData: '/img/scorecard/animated/friendliness.json',
    },
    skill: {
        backColor: '#F2F5FF',
        foreColor: '#2E5BFF',
        color: 'white',
        label: 'Skilled Service',
        img: BadgeSkill,
        animationData: '/img/scorecard/animated/skill.json',
    },
    efficiency: {
        backColor: '#F3EEFF',
        foreColor: '#8C54FF',
        color: 'white',
        label: 'Well Organised',
        img: BadgeEfficiency,
        animationData: '/img/scorecard/animated/efficiency.json',
    },
    rocket: {
        backColor: '#D2D8E4',
        foreColor: '#060A7F',
        color: 'white',
        label: 'Prompt',
        img: BadgeRocket,
        animationData: '/img/scorecard/animated/rocket.json',
    },
    hacker: {
        backColor: '#F3EEFF',
        foreColor: '#A40CBB',
        color: 'white',
        label: 'Computer Boy',
        img: BadgeHacker,
        animationData: '/img/scorecard/animated/hacker.json',
    },
    treatment: {
        backColor: '#FFF4F4',
        foreColor: '#F8516F',
        color: 'white',
        label: 'Good Treatment',
        img: BadgeTreatment,
        animationData: '/img/scorecard/animated/treatment.json',
    },
    default: {
        backColor: '#FEF2F7',
        foreColor: '#EE2779',
        color: 'white',
        label: '',
        img: BadgeCommunication,
        animationData: '/img/scorecard/animated/communication.json',
    },
}

export interface IScoreDataset {
    score: number
    startTime: number
    endTime: number
    label: string
}
export interface IScoreData {
    from: string
    graph: {
        target: number
        nextWeekLabel: string
        datasets: IScoreDataset[]
    }
    score: number
    scoreType: string
}
