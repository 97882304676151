import {
    INPSTrendGraph,
    INPSSummary,
    INPSStatsSummary,
    IFilterRule,
    INPSStatsSummaryBreakdown,
} from '@/entities'
import client from '@/api/client'
import qs from 'qs'
import { OfflineCache } from '@/mobile/src/utils/offlinecache'
import { cachedClient } from '@/api/clientCached'

const offlineCache = new OfflineCache('scorecard')

/*
    Gets the (Company) summary depending on the user's
    active filter stored serverside.
*/
export async function getNPSSummary(
    is_external = 0,
    time_unit = '',
    time_value = 0,
    user_id?: string,
    questionType = '',
    force_elastic = false
) {
    let paramsObj = { is_external } as any
    if (time_unit && time_value) {
        paramsObj = { ...paramsObj, time_unit, time_value }
    }
    if (user_id) {
        paramsObj = { ...paramsObj, user_id }
    }
    if (questionType) {
        paramsObj = { ...paramsObj, questionType }
    }
    paramsObj = { ...paramsObj, force_elastic }

    const params = qs.stringify(paramsObj)
    return offlineCache.cachedGet<INPSSummary>(client, `/nps/summary?${params}`)
}

export async function getNPSSummaries(
    questionType = '',
    is_external = 0,
    time_unit = '',
    time_value = 0,
    filter_by?: string,
    customFieldValue?: string,
    data_points?: number
) {
    let paramsObj = { is_external } as any
    if (questionType) {
        paramsObj = { ...paramsObj, questionType }
    }
    if (time_unit && time_value) {
        paramsObj = { ...paramsObj, time_unit, time_value }
    }
    if (filter_by) {
        const filterByContent = customFieldValue
            ? `${filter_by}:${customFieldValue}`
            : filter_by
        paramsObj = { ...paramsObj, filter_by: filterByContent }
    }
    if (data_points) {
        paramsObj = { ...paramsObj, data_points }
    }
    const params = qs.stringify(paramsObj)
    return client.get<INPSSummary | INPSSummary[]>(`/nps/summary?${params}`)
}

export async function getNPSTrendGraph(
    questionType = '',
    customDateRange = ''
) {
    let paramsObj = {}
    if (questionType) {
        paramsObj = { ...paramsObj, questionType, customDateRange }
    }
    const params = qs.stringify(paramsObj)
    return await offlineCache.cachedGet<INPSTrendGraph>(
        client,
        `/nps/trend-graph?${params}`
    )
}

export async function getWeeklyNPSTrendGraph(userId?: string) {
    return await offlineCache.cachedGet<INPSTrendGraph>(
        client,
        `/nps/` + (userId ? `user/${userId}/` : ``) + `weekly-trend-graph`
    )
}

export async function getNPSStatsSummary(
    questionType = '',
    time_unit = '',
    time_value = 0,
    force_elastic = false
) {
    let paramsObj = {}
    if (questionType) {
        paramsObj = { ...paramsObj, questionType }
    }
    if (time_unit && time_value) {
        paramsObj = { ...paramsObj, time_unit, time_value }
    }
    paramsObj = { ...paramsObj, force_elastic }

    const params = qs.stringify(paramsObj)
    return await offlineCache.cachedGet<INPSStatsSummary>(
        client,
        `/nps/stats-summary?${params}`
    )
}

export async function getNPSStatsSummaryBreakdown(
    refresh = '',
    questionType = '',
    time_unit = '',
    time_value = 0,
    force_elastic = false
) {
    let paramsObj = {}
    if (questionType) {
        paramsObj = { ...paramsObj, questionType }
    }
    if (time_unit && time_value) {
        paramsObj = { ...paramsObj, time_unit, time_value }
    }
    paramsObj = { ...paramsObj, force_elastic, refresh }

    const params = qs.stringify(paramsObj)
    return await offlineCache.cachedGet<INPSStatsSummaryBreakdown>(
        client,
        `/nps/stats-summary-breakdown?${params}`
    )
}

export async function getSentimentTrendGraph(column: string) {
    return await client.get<INPSTrendGraph>(`/nps/sentiment-graph/${column}`)
}

export async function getScoreData(
    days: string,
    metric: string,
    extraFilter: IFilterRule[] | null,
    overwriteFilter: boolean
) {
    const hashKey = btoa(JSON.stringify(extraFilter ?? metric))

    return offlineCache.cachedGet(
        cachedClient,
        `/nps/score-data/${days ?? '30'}?hashKey=${hashKey}`, // Add hash so each score data request is cached as cache by URL
        -1,
        {
            params: {
                extraFilter: extraFilter ? JSON.stringify(extraFilter) : '',
                questionType: metric,
                overwriteFilter: overwriteFilter,
            },
        }
    )
}
