<template>
    <div class="container">
        <SeasonalBanner @showSeasonalBanner="onToggleSeasonalBanner" />

        <div v-if="fromDrillDown" class="top-bar-buffer" />
        <div v-if="fromDrillDown" class="top-bar-container">
            <div class="top-bar">
                <Typography
                    component="span"
                    class="back navigation-text"
                    @click="backAction"
                >
                    <FontAwesomeIcon
                        class="chevron-left"
                        size="lg"
                        :icon="faChevronLeft"
                    />
                    {{ topBarBackLabel }}
                </Typography>
                <Typography key="NICE" component="span" class="title-text">
                    {{ topBarTitle }}
                </Typography>
                <div class="right-section" />
            </div>
        </div>

        <PullToRefreshWrapper
            ref="refreshContainerHome"
            :disable="showReportCard || notificationPanelOpen"
            :ptr-refreshing="loading"
            :refresh-container-classes="refreshContainerClasses"
            @refresh-action="pullToRefresh"
        >
            <div id="content-capped-width">
                <TransitionGroup appear>
                    <HomeLoadingState
                        v-if="loading"
                        key="loading"
                        :hide-profile="loading && hideProfile"
                    />

                    <!-- Show whole page error/no data message if there is an issue with all cards on page-->
                    <div
                        v-else-if="allDataIsEmpty || allRequestsHaveErrors"
                        key="error"
                        class="empty-data"
                    >
                        <Profile
                            key="profile"
                            :selected-user-name="drillDownUser.name"
                            :selected-user-type="selectedUserType"
                            :show-notifications="false"
                        />
                        <ErrorPage v-if="allRequestsHaveErrors" />
                        <EmptyPageHome v-else :message="emptyTopicMessage" />
                    </div>

                    <div v-else key="content">
                        <Profile
                            key="profile"
                            :selected-user-name="drillDownUser.name"
                            :selected-user-type="selectedUserType"
                            :from-drill-down="fromDrillDown"
                        />

                        <NotificationsPanel
                            v-if="notificationPanelOpen && hasNetworkConnection"
                            key="notification"
                        />

                        <HomeContent
                            v-else-if="!notificationPanelOpen"
                            :filter-key="filterKey"
                            :refresh-topic="refreshTopic"
                            :show-goal-tracking="showGoalTracking"
                            @set-show-report-card="setShowReportCard"
                            @toggle-show-goal-tracking="toggleShowGoalTracking"
                        />
                    </div>
                </TransitionGroup>
            </div>
        </PullToRefreshWrapper>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import NPSTargetGraph from '@/mobile/src/components/appV4/NPSTargetGraph.vue'
import NPSDisplay from '@/mobile/src/components/NPSDisplay.vue'
import FeatureContainer from '@/mobile/src/components/appV4/FeatureContainer.vue'
import PillButton from '@/components/PillButton.vue'
import Profile from '@/mobile/src/components/appV4/Profile.vue'
import ReportCard from '@/mobile/src/views/ReportCard.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import EmptyPageHome from '@/mobile/src/components/appV4/EmptyPageHome.vue'
import ErrorPage from '@/mobile/src/components/appV4/ErrorPage.vue'
import PullToRefreshWrapper from '@/mobile/src/components/appV4/PullToRefreshWrapper.vue'
import HomeLoadingState from '@/mobile/src/components/appV4/HomeLoadingState.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import SeasonalBanner from '@/mobile/src/views/appV4/SeasonalBanner.vue'
import NotificationsPanel from '@/mobile/src/components/appV4/home/NotificationsPanel.vue'
import HomeContent from '@/mobile/src/views/appV4/HomeContent.vue'
import { faChevronLeft } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { pendoTrackEvent } from '@/utils/pendo'
import { getFilterKey } from '@/mobile/src/utils/topics'
import { getLastMonthUsingTimezone } from '@/utils/time'

const ScoredataModule = namespace('scoredata')
const ReportCardModule = namespace('reportcard')
const TopicFeedbackModule = namespace('topicfeedback')
const GoalTrackingModule = namespace('goals')
const TeamFilter = namespace('teamFilter')
const TimeFilter = namespace('timeFilter')
const NotificationsModule = namespace('notifications')

@Component({
    components: {
        HomeContent,
        NotificationsPanel,
        SeasonalBanner,
        HomeLoadingState,
        PullToRefreshWrapper,
        ErrorPage,
        EmptyPageHome,
        FontAwesomeIcon,
        LoadingCard,
        PillButton,
        FeatureContainer,
        NPSDisplay,
        NPSTargetGraph,
        Profile,
        ReportCard,
        Typography,
    },
})
export default class Home extends Vue {
    @TeamFilter.Getter getHistory
    @TeamFilter.Getter backToPrevious
    @TeamFilter.Action removeHistory
    @TeamFilter.Action toggleSelected

    @TeamFilter.Action loadFilterOptions
    @TimeFilter.Action initTimeFilterOptions
    @TeamFilter.Getter teamFilterSelected?
    @TimeFilter.Getter timeFilterSelected?

    @ScoredataModule.Action loadScoreData
    @ScoredataModule.Getter scoreGraph
    @ScoredataModule.Getter scoredataError!: boolean
    @ScoredataModule.Getter emptyScoreData!: boolean

    @TopicFeedbackModule.Getter topicFeedbackError!: boolean
    @TopicFeedbackModule.Getter emptyTopicData!: boolean
    @TopicFeedbackModule.Getter emptyTopicMessage
    @TopicFeedbackModule.Action getTopicFeedback
    @TopicFeedbackModule.Action addTopicFilter
    @TopicFeedbackModule.Action clearTopicFilters

    @ReportCardModule.Getter showReportCard
    @ReportCardModule.Getter drillDownUser
    @ReportCardModule.Action loadReportCardData
    @ReportCardModule.Action setReportMonth
    @ReportCardModule.Action setShowReportCard
    @ReportCardModule.Action setDrillDownUser
    @ReportCardModule.Action setReportUserName

    @GoalTrackingModule.Getter goalTrackingData
    @GoalTrackingModule.Action loadGoalTrackingData

    @NotificationsModule.Getter notificationPanelOpen
    @NotificationsModule.Action startUnseenPolling
    @NotificationsModule.Action stopUnseenPolling

    @Action public closeSeasonalBanner
    @Action public loadCompanyDataFromCache

    @Getter readonly mobileQuestionType!: string
    @Getter public $companyVars
    @Getter hasNetworkConnection!: boolean

    @Getter userName
    @Getter teamFilterAdminRight

    private route = useRoute()
    private router = useRouter()
    private emitter = useEmitter()

    private showGoalTracking = false

    private dayRange = '30'
    public loading = false
    public fadeContent = false // Set content opacity to 0.1 when showing the seasonal banner info above

    private faChevronLeft = faChevronLeft

    private extraFilter = ''
    // top bar properties
    private fromDrillDown = false
    private topBarTitle = ''
    private topBarBackLabel = ''
    private hideProfile = false
    private refreshTopic = 0

    public async beforeDestroy() {
        // Kill the polling
        this.stopUnseenPolling()
    }

    public async created() {
        if (!this.hasNetworkConnection) {
            await this.loadCompanyDataFromCache()
        }
    }

    public async mounted() {
        this.clearTopicFilters()
        this.resetReportMonth()

        if (!this.fromDrillDown) {
            // Poll for notification
            this.startUnseenPolling()
        }

        // Displaying another users overview page from Teams drill-down
        this.extraFilter = String(
            this.route.params.extraFilter ? this.route.params.extraFilter : ''
        )

        if (
            this.extraFilter != '' &&
            JSON.parse(this.extraFilter)?.fromDrillDown &&
            this.hasNetworkConnection
        ) {
            this.addTopicFilter(JSON.parse(this.extraFilter))
            this.refreshTopic = Math.random()
            this.hideProfile = true
            this.fromDrillDown = true
        }

        this.loadFilteredScoreData()
        this.triggerReportCard()
        // set current sign-in user.
        this.setReportUserName(this.userName)
        // Check the drillDown or not.
        this.setDrillDownUserName()
        // Check if the ReportCard is available or not
        await this.loadReportCardData()

        if (!this.goalTrackingData?.length && this.hasNetworkConnection) {
            await this.loadGoalTrackingData(this.mobileQuestionType)
        }

        if (!this.scorecardParamsLoaded) {
            await this.loadFilterOptions(!this.teamFilterAdminRight)
            await this.initTimeFilterOptions()
        }
    }

    @Watch('route.params')
    public async onRouteParamsChanged() {
        this.loading = true
        this.clearTopicFilters()
        this.extraFilter = ''
        this.triggerReportCard()
        this.loadFilteredScoreData()
        this.fromDrillDown = false
        this.loading = false
    }

    @Watch('showReportCard')
    private onShowReportCardChanged() {
        if (this.showReportCard === false) {
            this.resetReportMonth()
        }

        this.emitter?.emit('stopScroll', this.showReportCard)

        // scroll the overview page to top if ReportCard is pop-up.
        let homePage = document.getElementById('page-content') as HTMLElement
        homePage.scrollTo({ top: 0, behavior: 'smooth' })
    }

    private loadFilteredScoreData() {
        const extraFilter = this.extraFilter
            ? JSON.parse(this.extraFilter)
            : null
        this.loadScoreData({ days: this.dayRange, extraFilter: [extraFilter] })

        if (extraFilter && extraFilter?.fromDrillDown) {
            this.fromDrillDown = true
            this.topBarTitle =
                extraFilter.groupName ??
                extraFilter.column.replace('_c', '').replace('_', ' ')
            this.topBarBackLabel =
                this.getHistory[this.getHistory.length - 1]?.customSelectionName
        }

        if (!this.scoreGraph) {
            this.loadScoreData({ days: this.dayRange })
        }
    }

    private async triggerReportCard() {
        if (this.route.query.reportcard) {
            // show reportcard popup from PushNotification
            await this.loadReportCardData(true)
            this.setShowReportCard(true)
            await this.router.replace({ query: {} })
        }
    }

    public async pullToRefresh() {
        this.closeSeasonalBanner()
        this.loading = true
        this.resetReportMonth()

        if (this.extraFilter != '') {
            await this.loadScoreData({
                days: this.dayRange,
                extraFilter: [this.extraFilter],
                forceRefresh: true,
            })
        } else {
            await this.loadScoreData({
                days: this.dayRange,
                forceRefresh: true,
            })
        }

        await this.loadReportCardData()
        await this.loadGoalTrackingData(this.mobileQuestionType)
        await this.getTopicFeedback({ forceRefresh: true })
        this.loading = false
    }

    private resetReportMonth() {
        const previousMonth = getLastMonthUsingTimezone(
            this.$companyVars.timezone
        )
        this.setReportMonth(previousMonth)
    }

    public get allRequestsHaveErrors(): boolean {
        return this.scoredataError && this.topicFeedbackError
    }

    public get allDataIsEmpty(): boolean {
        return this.emptyScoreData && this.emptyTopicData
    }

    /**
     * Drill-down user selected passed into here as format of Filter string.
     * This needs to be added to Vuex state to be used in ReportCard pop-up page.
     */
    public setDrillDownUserName(): void {
        if (!this.extraFilter || this.extraFilter == '') {
            // remove drill-down user value if back to view current user Overview data.
            this.setDrillDownUser({ name: undefined })
            return
        }

        // save to Vuex for used in ReportCard component
        this.setDrillDownUser({ name: JSON.parse(this.extraFilter)?.value[0] })
    }

    public get selectedUserType(): string {
        if (!this.extraFilter || this.extraFilter == '') {
            return ''
        }
        const extraFilter = JSON.parse(this.extraFilter)

        if (!extraFilter) {
            return ''
        }

        const field =
            extraFilter.groupName ??
            extraFilter.column.replace(/_c$/, '').replace('_', ' ')
        return this.uppercaseFirstWords(field)
    }

    private uppercaseFirstWords(value: string) {
        const words = value.split(' ')
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
        return words.join(' ')
    }

    public toggleShowGoalTracking(show: boolean) {
        let descriptor = show ? 'show' : 'hide'
        pendoTrackEvent(descriptor + '_goal_tracking')
        this.showGoalTracking = show
    }

    public async backAction() {
        // Be very careful if it is needed to change below order.
        this.removeHistory()
        await this.router.replace('/teams')
        let history = this.backToPrevious
        // Topics component will be loaded after route changed.
        this.toggleSelected(history)
    }

    public onToggleSeasonalBanner(show: boolean) {
        this.fadeContent = show
    }

    public get refreshContainerClasses() {
        if (this.fadeContent) {
            // This class sets an opacity of 0.1 on the content wrapper so an overlay can be shown prominently
            return 'fade-refresh-container'
        }
        return this.allDataIsEmpty || this.allRequestsHaveErrors
            ? 'full-height'
            : ''
    }

    /**
     * Returns true if all params required for scorecard loading have been loaded.
     */
    private get scorecardParamsLoaded(): boolean {
        return (
            this.timeFilterSelected !== null && this.teamFilterSelected !== null
        )
    }

    /**
     * Filter key for Topic component that signals filter is changed (between tabs), to load data.
     */
    private get filterKey(): string {
        return this.scorecardParamsLoaded
            ? getFilterKey(this.teamFilterSelected, this.timeFilterSelected)
            : ''
    }

    public get showHuddleOption(): boolean {
        const temporaryTenantCheck =
            this.$companyVars.domain === 'jarvisfitnessdev'

        return Boolean(
            temporaryTenantCheck &&
                this.$companyVars.has_huddle &&
                !this.fromDrillDown
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/animations.less';
@import '~@/styles/palette.less';
@import '~@/styles/variables.less';
@import '~@/styles/rain/variables.less';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#content-capped-width {
    max-width: @mobileMaxContentWidth;
    margin: auto;
}

.empty-data {
    @profileHeight: 107px;
    height: calc(
        100vh - @mobileNavHeightPadded - @filterHeaderHeight - @profileHeight
    );
    display: flex;
    flex-direction: column;
}

.top-bar-buffer {
    margin-top: 10vh;
}

.fade-refresh-container {
    opacity: 0.1;
}

.top-bar-container {
    padding-top: env(safe-area-inset-top);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    z-index: 1;

    .top-bar {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title-text {
            flex: 2;
            align-items: center;
            color: @white;
            font-size: @fontSize-md;
            font-weight: @fontWeight-medium;
            letter-spacing: @letterSpacing-base;
            line-height: @lineHeight-md;
            text-align: center;
            text-transform: capitalize;
        }

        .back,
        .right-section {
            flex: 1;
            width: 100%;
        }
    }

    .navigation-text {
        align-items: center;
        color: @white;
        font-size: @fontSize-sm;
        font-weight: @fontWeight-medium;
        line-height: @lineHeight-sm;
        letter-spacing: @letterSpacing-2xl;
        text-transform: uppercase;

        &.back {
            align-items: center;
            display: inline-flex;

            .chevron-left {
                margin-right: 5px;
            }
        }
    }
}

.loading-card-animations();
</style>
