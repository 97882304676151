<template>
    <WebViewScreen
        no-pad
        class="insights"
        :show-go-back="true"
        disable-when-offline
    >
        <template #custom-title>
            <TwoLineHeader title="Growth" :subtitle="friendlyTimeRange" />
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>

        <Scrollable :loading="isLoadingInsightsData" :pull-down="onPullDown">
            <div class="header-container">
                <div class="heading">{{ name }}</div>
                <RouterLink :to="{ name: 'insightsDetail' }">
                    <BaseButton
                        v-if="insightsDetails"
                        primary
                        small
                        class="primary-action"
                        >View Insights
                    </BaseButton>
                </RouterLink>
            </div>
            <div class="filters">
                <div class="caret" @click="toggleFilterVisibility">
                    <img
                        v-if="showFilters"
                        src="@/pages/conversation/assets/images/chevron-up.svg"
                    />
                    <img
                        v-else
                        src="@/pages/conversation/assets/images/chevron-down.svg"
                    />
                </div>
                <div class="filter-title">
                    View {{ name }} by
                    <span class="filter-value">{{ viewByGroupTypeName }}</span>
                </div>
                <VueSlideUpDown :active="showFilters" :duration="250">
                    <div class="horizontal-scroll-container">
                        <div
                            v-for="(group, index) in teamHierarchy"
                            :key="index"
                            class="scrolling-item"
                        >
                            <PillButton
                                :label="group.groupName"
                                :is-active="getIsActive(group?.fieldMapping)"
                                :disabled="index <= currentGroupLevel"
                                @click="setActiveDataType(index)"
                            />
                        </div>
                    </div>
                </VueSlideUpDown>
                <VueSlideUpDown :active="showStatusFilterTitle" :duration="250">
                    <div class="filter-title">
                        Filter {{ pluralizedViewByGroupTypeName }} that are
                        <span class="filter-value">{{ formattedSelectedStatuses }}
                        </span>
                        <FontAwesomeIcon
                            :icon="faInfoCircle"
                            size="lg"
                            @click="showModal"
                        />
                    </div>
                </VueSlideUpDown>
                <VueSlideUpDown :active="showFilters" :duration="250">
                    <div class="horizontal-scroll-container">
                        <div
                            v-for="status in OrderedInsightsStatuses"
                            :key="status"
                            class="scrolling-item"
                        >
                            <StatusPillButton
                                :status="status"
                                :label="status"
                                :is-active="selectedStatuses[status]"
                                @click="toggleStatus(status)"
                            />
                        </div>
                    </div>
                    <div class="reset-filters-container">
                        <div class="reset-filters" @click="resetFilters">
                            Reset Filters
                        </div>
                    </div>
                </VueSlideUpDown>
                <hr class="filter-divider" />
            </div>

            <div class="horizontal-scroll-container metrics-container">
                <div
                    v-if="activeMetrics.includes('nps')"
                    class="scrolling-item"
                >
                    <InsightsMetric
                        :active="selectedMetric === InsightsMetrics.NPS"
                        :delta="isNpsChangePositive ? 'positive' : 'negative'"
                        :title="nps"
                        :subtitle="InsightsMetrics.NPS"
                        @click="onClickMetric(InsightsMetrics.NPS, 'nps')"
                    />
                </div>
                <div v-if="showCsatTile" class="scrolling-item">
                    <InsightsMetric
                        :active="selectedMetric === InsightsMetrics.CSAT"
                        :delta="isCsatChangePositive ? 'positive' : 'negative'"
                        :title="csatRating"
                        :subtitle="InsightsMetrics.CSAT"
                        @click="onClickMetric(InsightsMetrics.CSAT, 'csat')"
                    />
                </div>
                <div v-if="showFivestarTile" class="scrolling-item">
                    <InsightsMetric
                        :active="selectedMetric === InsightsMetrics.FIVESTAR"
                        :delta="
                            isFivestarChangePositive ? 'positive' : 'negative'
                        "
                        :title="fivestarRating"
                        :subtitle="InsightsMetrics.FIVESTAR"
                        @click="
                            onClickMetric(InsightsMetrics.FIVESTAR, 'fivestar')
                        "
                    />
                </div>

                <div v-if="repeatCustomerMetricEnabled" class="scrolling-item">
                    <InsightsMetric
                        v-if="isRepeatVisitTrackingConfigured"
                        :active="
                            selectedMetric === InsightsMetrics.REPEAT_VISIT
                        "
                        :delta="
                            isRepeatVisitChangePositive
                                ? 'positive'
                                : 'negative'
                        "
                        :title="`${repeatCustomer}%`"
                        :subtitle="InsightsMetrics.REPEAT_VISIT"
                        @click="onClickMetric(InsightsMetrics.REPEAT_VISIT)"
                    />
                    <InsightsMetric
                        v-else
                        :active="
                            selectedMetric === InsightsMetrics.REPEAT_VISIT
                        "
                        title="😤"
                        :subtitle="InsightsMetrics.REPEAT_VISIT"
                        @click="onClickMetric(InsightsMetrics.REPEAT_VISIT)"
                    />
                </div>
                <div v-if="customerRetentionEnabled" class="scrolling-item">
                    <InsightsMetric
                        :active="selectedMetric === InsightsMetrics.RETENTION"
                        :delta="
                            isCustomRetentionChangePositive
                                ? 'positive'
                                : 'negative'
                        "
                        :title="`${customerRetention}%`"
                        :subtitle="InsightsMetrics.RETENTION"
                        @click="onClickMetric(InsightsMetrics.RETENTION)"
                    />
                </div>
                <div v-if="customerTenureEnabled" class="scrolling-item">
                    <InsightsMetric
                        :active="selectedMetric === InsightsMetrics.TENURE"
                        :delta="
                            isCustomTenureChangePositive
                                ? 'positive'
                                : 'negative'
                        "
                        :title="`${customerTenure}`"
                        :subtitle="InsightsMetrics.TENURE"
                        @click="onClickMetric(InsightsMetrics.TENURE)"
                    />
                </div>
            </div>
            <GradientHighlightedCard class="insights-table">
                <div
                    v-if="
                        selectedMetric === InsightsMetrics.REPEAT_VISIT &&
                        !isRepeatVisitTrackingConfigured
                    "
                    class="not-available"
                >
                    <div class="message-title">Not available</div>
                    <div class="message">
                        Contact Support about tracking your Repeat Customers
                    </div>
                    <BaseButton
                        primary
                        small
                        class="contact-support-button"
                        :click="onClickContactSupport"
                        >Contact Support</BaseButton>
                </div>
                <InsightsTable
                    v-else
                    :name="name"
                    :view-by-group-type-name="viewByGroupTypeName"
                    :pluralized-view-by-group-type-name="
                        pluralizedViewByGroupTypeName
                    "
                    :previous-group-name="previousGroupName"
                    :team-scoreboard-groups="teamScoreboardGroups"
                    :repeat-visits-groups="repeatVisitsGroups"
                    :statuses="currentStatuses"
                    :selected-statuses="selectedStatuses"
                    :is-leaf="viewByGroupTypeIsLeafLevel"
                    :is-root="isCurrentRootLevel"
                    :selected-metric="selectedMetric"
                    :is-loading-insights-data="isLoadingInsightsData"
                    @back="onUpOneLevel"
                    @drilldown="onDrillDown"
                />
            </GradientHighlightedCard>
        </Scrollable>

        <Modal
            :show="isTooltipModalVisible"
            allow-click-out
            allow-escape
            title="What is this?"
            size="medium"
            :close="() => (isTooltipModalVisible = false)"
        >
            <div class="paragraph">
                NPS scores are categorised into 3 different statuses: Gold,
                Silver and Bronze.
            </div>
            <div class="paragraph">
                These help you identify who are your high and low performers.
                The statuses are based on the range of NPS scores across the
                entire group.
            </div>
        </Modal>
    </WebViewScreen>
</template>

<script lang="ts">
import BaseBlock from '@/components/BaseBlock.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import GradientHighlightedCard from '@/components/GradientHighlightedCard.vue'
import Modal from '@/components/Modal.vue'
import PillButton from '@/components/PillButton.vue'
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { CompanyFeatures } from '@/entities'
import { ITimeRange } from '@/entities/insights'
import {
    TsGroupEntity,
    TsGroupRepeatVisitsSummaryEntity,
    TsGroupSummaryEntity,
    TsNpsSummaryEntity,
    TsTeamGroupEntity,
} from '@/entities/teamscoreboard'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import InsightsTable from '@/mobile/src/components/Insights/InsightsTable.vue'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import StatusPillButton from '@/mobile/src/components/Insights/StatusPillButton.vue'
import {
    GroupStatusMap,
    InsightsMetric as InsightsMetrics,
    InsightsStatus,
    OrderedInsightsStatuses,
    SelectedStatuses,
} from '@/mobile/src/types/insights'
import { friendlyCustomerTenure } from '@/mobile/src/utils/insights'
import { LooseObject } from '@/pages/appstore/entities/app'
import { AppLauncher } from '@capacitor/app-launcher'
import { faInfoCircle } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import pluralize from 'pluralize'
import qs from 'qs'
import { Component, Vue } from 'vue-facing-decorator'
import VueSlideUpDown from 'vue-slide-up-down'
import { Action, Getter } from 'vuex-facing-decorator'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'
import { useRouter } from 'vue-router'
import { formatScore } from '@/utils/number'

@Component({
    components: {
        BaseBlock,
        BaseButton,
        BaseCard,
        FontAwesomeIcon,
        GradientHighlightedCard,
        InsightsMetric,
        InsightsTable,
        InsightsTimePickerMobile,
        Modal,
        PillButton,
        Scrollable,
        StatusPillButton,
        VueSlideUpDown,
        WebViewScreen,
        TwoLineHeader,
    },
})
export default class GrowthMetrics extends Vue {
    readonly InsightsMetrics = InsightsMetrics
    readonly OrderedInsightsStatuses = OrderedInsightsStatuses
    readonly faInfoCircle = faInfoCircle

    private router = useRouter()

    @Getter readonly timeRange!: ITimeRange
    @Getter readonly hasCsatData!: boolean
    @Getter readonly hasFivestarData!: boolean
    @Getter readonly friendlyTimeRange!: string
    @Getter readonly selectedMetric!: InsightsMetric
    @Getter readonly selectedStatuses!: SelectedStatuses
    @Getter readonly teamHierarchy!: TsTeamGroupEntity[]
    @Getter readonly viewByGroupType!: TsTeamGroupEntity
    @Getter readonly insightsDetails!: TsGroupSummaryEntity
    @Getter readonly insightsRepeatVisits!: TsGroupRepeatVisitsSummaryEntity
    @Getter readonly npsInsightsSummary!: TsNpsSummaryEntity
    @Getter readonly csatInsightsSummary!: TsNpsSummaryEntity
    @Getter readonly fivestarInsightsSummary!: TsNpsSummaryEntity
    @Getter readonly isCurrentRootLevel!: boolean
    @Getter readonly viewByGroupTypeIsLeafLevel!: boolean
    @Getter readonly currentStatuses!: GroupStatusMap
    @Getter readonly currentGroupName!: string
    @Getter readonly previousGroupName!: string
    @Getter readonly currentGroupLevel!: string
    @Getter readonly companyProps!: LooseObject
    @Getter readonly isLoadingInsightsData!: boolean
    @Getter readonly showFilters!: boolean
    @Getter readonly isRepeatVisitTrackingConfigured!: boolean
    @Getter readonly getFeatureValue!: (featureName: CompanyFeatures) => any
    @Getter readonly $companyVars
    @Getter readonly activeMetrics!: string[]

    @Action setHighlightedGroup
    @Action loadTeamHierarchy
    @Action refreshData
    @Action goBack
    @Action drillDownOneLevel
    @Action selectMetric
    @Action selectViewByHierarchyLevel
    @Action resetInsights
    @Action updateTimeRange
    @Action setShowFilters
    @Action setSelectedStatus
    @Action loadIsRepeatVisitTrackingConfigured

    isTooltipModalVisible = false

    async mounted() {
        // load team hierarchy if not loaded
        if (!this.viewByGroupType) {
            await this.loadTeamHierarchy()
        }

        await this.loadIsRepeatVisitTrackingConfigured()

        this.refreshData()
    }

    getIsActive(fieldMapping?: string): boolean {
        if (fieldMapping && this.viewByGroupType) {
            return this.viewByGroupType.fieldMapping === fieldMapping
        }
        return false
    }

    get name() {
        return this.currentGroupName || this.companyProps.name
    }

    get nps() {
        return this.npsInsightsSummary?.nps.toFixed(1) ?? '0.0'
    }

    get fivestarRating() {
        return formatScore(this.fivestarInsightsSummary?.nps ?? '0.0', true)
    }

    get csatRating() {
        let csatRating = formatScore(
            this.csatInsightsSummary?.nps ?? '0.0',
            true,
            this.$companyVars.has_display_csat_as_percentage
        )

        if (this.$companyVars.has_display_csat_as_percentage) {
            csatRating += '%'
        }

        return csatRating
    }

    get showFivestarTile() {
        return (
            this.activeMetrics.includes('fivestar') &&
            (this.hasFivestarData || this.fivestarInsightsSummary?.total)
        )
    }

    get showCsatTile() {
        return (
            this.activeMetrics.includes('csat') &&
            (this.hasCsatData || this.csatInsightsSummary?.total)
        )
    }

    get repeatCustomer() {
        return (
            this.insightsRepeatVisits?.repeatVisitPercentage.toFixed(1) ?? '0.0'
        )
    }

    get customerRetention() {
        return this.insightsRepeatVisits?.customerRetention.toFixed(1) ?? '0.0'
    }

    get customerTenure() {
        return friendlyCustomerTenure(this.insightsRepeatVisits?.customerTenure)
    }

    get isNpsChangePositive() {
        return (this.npsInsightsSummary?.change ?? 0) >= 0
    }

    get isCsatChangePositive() {
        return (this.csatInsightsSummary?.change ?? 0) >= 0
    }

    get isFivestarChangePositive() {
        return (this.fivestarInsightsSummary?.change ?? 0) >= 0
    }

    get isRepeatVisitChangePositive() {
        return (this.insightsRepeatVisits?.repeatVisitPercentageDelta ?? 0) >= 0
    }

    get isCustomRetentionChangePositive() {
        return (this.insightsRepeatVisits?.customerRetentionDelta ?? 0) >= 0
    }

    get isCustomTenureChangePositive() {
        return (this.insightsRepeatVisits?.customerTenureDelta ?? 0) >= 0
    }

    get teamScoreboardGroups() {
        return this.insightsDetails?.tsgroups
    }

    get repeatVisitsGroups() {
        return this.insightsRepeatVisits?.tsgroups
    }

    get pluralizedViewByGroupTypeName() {
        return pluralize(this.viewByGroupTypeName || '')
    }

    get viewByGroupTypeName() {
        return this.viewByGroupType?.groupName
    }

    get formattedSelectedStatuses() {
        return OrderedInsightsStatuses.filter(
            (status) => this.selectedStatuses[status]
        ).join(', ')
    }

    get showStatusFilterTitle() {
        return (
            this.showFilters ||
            Object.values(this.selectedStatuses).some((status) => status)
        )
    }

    get repeatCustomerMetricEnabled() {
        return this.getFeatureValue(
            CompanyFeatures.enable_insights_repeat_customers
        )
    }

    get customerRetentionEnabled() {
        return this.getFeatureValue(CompanyFeatures.enable_insights_retention)
    }

    get customerTenureEnabled() {
        return this.getFeatureValue(CompanyFeatures.enable_insights_tenure)
    }

    navigateToPersonScorecard(userId: number) {
        this.router.push(`/scorecard/user/${userId}`)
    }

    // go up one level in hierarchy
    onUpOneLevel() {
        if (this.isLoadingInsightsData) {
            return
        }
        this.goBack()
    }

    onDrillDown(row: TsGroupEntity) {
        if (this.isLoadingInsightsData) {
            return
        }

        // navigate to scorecard at leaf level
        if (row.user_id) {
            this.navigateToPersonScorecard(row.user_id)
            return
        }
        this.drillDownOneLevel(row.group)
    }

    onClickMetric(selected: InsightsMetrics, questionType) {
        // update user select metric
        this.selectMetric({ selected, questionType })
    }

    setActiveDataType(index: number) {
        if (this.isLoadingInsightsData) {
            return
        }
        this.selectViewByHierarchyLevel(index)
    }

    toggleStatus(status: InsightsStatus) {
        this.setSelectedStatus({
            status,
            value: !this.selectedStatuses[status],
        })
    }

    toggleFilterVisibility() {
        this.setShowFilters(!this.showFilters)
    }

    resetFilters() {
        this.resetInsights()
    }

    onPullDown() {
        return this.refreshData(true)
    }

    showModal() {
        this.isTooltipModalVisible = true
    }

    onClickContactSupport() {
        const supportEmailAddress = 'support@asknice.ly'
        const params = qs.stringify({
            subject: 'Configuring Repeat Visit Tracking',
        })
        AppLauncher.openUrl({ url: `mailto:${supportEmailAddress}?${params}` })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.insights {
    background-color: @lightGrey;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: @gutterSpacing;
    margin-bottom: 2 * @gutterSpacing;

    > .primary-action {
        flex: none;
    }
}

.heading {
    .heading();

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.primary-action {
    margin-left: @gutterSpacing;
    text-transform: uppercase;
    white-space: nowrap;
}

.filters {
    position: relative;

    > .caret {
        position: absolute;
        top: -2px;
        right: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.filter-title {
    .title();
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 0 @gutterSpacing 8px @gutterSpacing;

    .filter-value {
        color: @grey90;
        font-weight: 900;
    }
}

.horizontal-scroll-container {
    margin-bottom: @gutterSpacing;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    > .scrolling-item {
        flex: 0 0 auto;
    }

    &.metrics-container {
        scroll-snap-type: x proximity;
        scroll-padding-left: @gutterSpacing;

        > .scrolling-item {
            scroll-snap-align: start;
            flex: 1 0 auto;
        }
    }
}

.scrolling-item {
    padding-right: @gutterSpacing;

    &:first-child {
        padding-left: @gutterSpacing;
    }
}

.reset-filters-container {
    display: flex;
    justify-content: center;
    margin: 0 @gutterSpacing;
}

.reset-filters {
    .title();
    color: @blue;
    line-height: 20px;
    letter-spacing: 0.5px;
    padding: @gutterSpacing;
}

.filter-divider {
    border: none;
    height: 1px;
    margin: @gutterSpacing;
    margin-top: 0;
    background-color: @greyBlue;
}

.not-available {
    text-align: center;
    padding: 0 @gutterSpacing;
}

.message-title {
    .heading();
    margin-top: @gutterSpacing * 3;
}

.message {
    margin-top: @gutterSpacing * 2;
}

.contact-support-button {
    text-transform: uppercase;
    margin-top: @gutterSpacing * 2;
    margin-bottom: @gutterSpacing * 3;
}

.insights-table {
    margin: @gutterSpacing;
    margin-top: 0;
}

.paragraph {
    + .paragraph {
        margin-top: 2 * @gutterSpacing;
    }
}
</style>
