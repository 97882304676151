<template>
    <div class="container">
        <div class="message-container">
            <Loading
                v-if="composable.loading.value"
                :loading="composable.loading.value"
            />
            <div v-else>
                <ChatMessages
                    v-if="convQuestions"
                    v-bind="$attrs"
                    ref="chatMessages"
                    :has-network-connection="hasNetworkConnection"
                    class="msgs"
                    :messages="chatMessages"
                    :custom-data="chatCustomData"
                    :chat-status="chatStatus"
                    :avatar="composable.avatar.value"
                    :name="composable.abbrName.value"
                    :is-user-basic="
                        userPermissions.includes('ROLE_USER_BASICS')
                    "
                    :conv-questions="convQuestions"
                    no-pad
                    @undo-chat="onUndoChat"
                />

                <div class="footer">
                    <!-- buttons -->
                    <ScreenBottomButton
                        v-if="!editingNote && animated && hasNetworkConnection"
                    >
                        <div class="buttons">
                            <button
                                v-if="
                                    userPermissions.includes('ROLE_ADD_NOTES')
                                "
                                type="button"
                                class="btn primary-btn"
                                @click="editNote"
                            >
                                Internal Note
                            </button>
                            <button
                                v-if="canReply"
                                type="button"
                                class="btn primary-btn"
                                :class="{
                                    disabled: composable.dontContact.value,
                                }"
                                :title="replyTooltip"
                                @click="goReply"
                            >
                                Email Reply
                            </button>
                        </div>
                    </ScreenBottomButton>

                    <!-- editing box -->
                    <transition name="fade">
                        <div v-if="editingNote">
                            <div class="scrolling-container">
                                <!-- note box -->
                                <div class="note-container">
                                    <!-- quill editor -->
                                    <div>
                                        <template v-if="chatTags">
                                            <template v-if="userHasMlp">
                                                <div class="top-actions">
                                                    <div
                                                        v-if="
                                                            mentionedUsers.length >
                                                            0
                                                        "
                                                        class="mark-urgent-action"
                                                        @click="
                                                            markAsUrgent =
                                                                !markAsUrgent
                                                        "
                                                    >
                                                        <img
                                                            v-if="markAsUrgent"
                                                            src="@/assets/img/Icons/exclamation-red.svg"
                                                            width="8"
                                                            height="20"
                                                            alt="Mark Urgent"
                                                        />
                                                        <img
                                                            v-else
                                                            src="@/assets/img/Icons/exclamation-grey.svg"
                                                            width="8"
                                                            height="20"
                                                            alt="Unmark Urgent"
                                                        />
                                                    </div>
                                                </div>
                                                <ChatInput
                                                    :content="note"
                                                    :mentions="mentionUsers"
                                                    :tags="chatTags.tags"
                                                    placeholder="Write an Internal Note (You can add a hashtag here with #. You can also mention your teammate with @ and their name, and tap the exclamation mark to mark it as urgent."
                                                    :on-update="onChatInput"
                                                    custom-class="mobile-note"
                                                    @save="saveNote"
                                                    @mentionAdded="
                                                        onMentionAdded
                                                    "
                                                    @mentionDeleted="
                                                        onMentionDeleted
                                                    "
                                                />
                                            </template>
                                            <ChatInput
                                                v-else
                                                :content="note"
                                                :mentions="mentionUsers"
                                                :tags="chatTags.tags"
                                                placeholder="Write an Internal Note (You can add a hashtag here with # or mention your teammate with @ and their name)"
                                                :on-update="onChatInput"
                                                custom-class="mobile-note"
                                                @save="saveNote"
                                            />
                                        </template>
                                    </div>
                                    <!-- send button -->
                                    <div class="actions">
                                        <BaseButton
                                            v-if="userHasMlp"
                                            class="add-btn"
                                            :disabled="!addEnabled"
                                            :loading="savingNote"
                                            @click="saveNote"
                                        >
                                            Add
                                        </BaseButton>
                                        <button
                                            v-else
                                            type="button"
                                            class="send-btn btn secondary-btn"
                                            :class="{ disabled: !addEnabled }"
                                            :disabled="!addEnabled"
                                            @click="saveNote"
                                        >
                                            Add
                                            <SimpleSpinner
                                                v-if="savingNote"
                                                size="small"
                                                class="spinner"
                                                line-bg-color="#2E5BFF"
                                                line-fg-color="#fff"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { Component, Setup, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { addNote, getConvQuestions, getMentionUsers, getTags } from '@/api/chat'
import { ChatMention, ChatStatus, ChatTags } from '@/entities/chat'
import ChatMessages from '@/components/Chat/ChatMessages.vue'
import { chatResponseScreenComposable } from '@/pages/chat/responses/chatResponseScreenComposable'
import Loading from '@/components/Loading.vue'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import ScreenBottomButton from '@/components/Notices/ScreenBottomButton.vue'
import BaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { extractNoteMentions } from '@/utils/notices'
import { sendMoment } from '@/api/notices'
import { MessageTypeLabel, ModalSource } from '@/mobile/src/types/messageTypes'
import ChatInput from '@/components/Chat/ChatInput.vue'

@Component({
    components: {
        ScreenBottomButton,
        ChatInput,
        ChatMessages,
        Loading,
        SimpleSpinner,
        BaseButton,
    },
})
export default class ChatResponseMessagesScreen extends Vue {
    @Getter protected chatNote!: string
    @Getter hasNetworkConnection!: boolean
    @Getter public $companyVars
    @Getter public chatCustomData
    @Getter public chatQuestionId
    @Getter public chatMessages
    @Getter public userHasMlp
    @Getter public userPermissions

    @Action protected setChatNote!: (note: string) => void
    @Action protected setChatReply!: ({ from, subject, message }) => void
    @Action protected reloadChatCustomData!: () => void
    @Action protected loadChat!: () => void
    @Action protected markTaskUrgent

    protected convQuestions: ConvUIQuestions[] | [] = []
    protected mentionUsers: ChatMention[] = []
    public chatTags: ChatTags | null = null

    protected note = ''
    protected editingNote = false
    protected savingNote = false
    protected markAsUrgent = false
    protected mentionedUsers: string[] = []

    protected animated = true

    public route = useRoute()
    public router = useRouter()

    @Setup(() => chatResponseScreenComposable())
    public composable

    public async mounted() {
        this.composable.loading.value = true

        await Promise.all([this.loadConvQuestions(), this.loadTags()])

        if (this.canLoadNotices) {
            await this.loadMentionUsers()
        }

        this.note = this.chatNote

        this.composable.loading.value = false

        if (this.route.params.addNote) {
            this.editNote()
        }
    }

    public get canReply() {
        return (
            this.userPermissions?.includes('ROLE_USER_BASICS') &&
            !Number(this.chatCustomData?.anom)
        )
    }

    public get canLoadNotices() {
        return (
            this.userPermissions?.includes('ROLE_USER_BASICS') ||
            this.userPermissions?.includes('ROLE_SEND_NOTICES') ||
            this.userPermissions?.includes('ROLE_ADD_NOTES')
        )
    }

    protected get chatStatus(): ChatStatus {
        return {
            note: '',
            reply: '',
            editing: 0,
            reply_review: false,
            editing_template: -1,
        }
    }

    protected editNote() {
        if (this.editingNote || this.composable.dontContact.value) {
            return
        }

        this.editingNote = true
    }

    protected closeNote() {
        if (!this.editingNote) {
            return
        }

        this.editingNote = false

        // TODO: It's a work around. `animated` is to make sure the bottom buttons is shown after the fade-out
        // animation of note box. We should apply animation to the buttons later
        this.animated = false
        setTimeout(() => {
            this.animated = true
        }, 400)
    }

    protected onChatInput(text) {
        this.note = text

        this.setChatNote(this.note)
    }

    protected onUndoChat(message) {
        this.setChatReply({
            from: message.from,
            subject: message.subject,
            message: message.message,
        })
    }

    protected get replyTooltip() {
        if (this.composable.dontContact.value) {
            return 'This contact does not wish to be contacted'
        } else if (this.composable.isPhoneNumber.value) {
            return 'This contact may not be able to reply to your SMS'
        } else {
            return ''
        }
    }

    private get addEnabled() {
        return this.note.trim().length && !this.savingNote
    }

    protected async saveNote() {
        if (this.savingNote) {
            return
        }

        this.savingNote = true

        // process mentions before save
        let note = this.processNoteMentions(this.note)
        note = note.replace(/(?:\s|^)#([^\]]\w+)\b/g, '[#$1](tag:$1)') // hash tag
        const selectedMentions = extractNoteMentions(this.mentionUsers, note)

        if (selectedMentions) {
            await sendMoment({
                mentions: selectedMentions,
                question: {
                    id: this.chatQuestionId,
                    comment: '',
                },
                scheduled_at: '',
                message: note,
                type: MessageTypeLabel.internalNote,
                recipients: [],
                source: ModalSource.FeedbackDetailScreen,
            })
        } else {
            await addNote(this.chatQuestionId, note, this.markAsUrgent)
        }

        this.editingNote = false
        this.savingNote = false

        if (this.markAsUrgent && this.route.params.taskId) {
            this.markTaskUrgent(this.route.params.taskId)
        }

        await Promise.all([this.reloadChatCustomData(), this.loadChat()])

        this.note = ''
        this.markAsUrgent = false
        this.mentionedUsers = []
        this.setChatNote(this.note)
    }

    protected async goReply() {
        this.setChatReply({
            from: this.chatCustomData.from_name.value,
            subject: this.chatCustomData.default_subject,
            message: '',
        })

        await this.router.push({
            name: 'chatResponsesEmailReply',
            params: { id: this.chatQuestionId.toString() },
        })
    }

    private async loadConvQuestions() {
        const chatQuestionId = isNaN(this.chatQuestionId)
            ? this.$route.params.id
            : this.chatQuestionId
        if (chatQuestionId) {
            const result = await getConvQuestions(chatQuestionId)
            this.convQuestions = result.data
        }
    }

    private async loadMentionUsers() {
        const result = await getMentionUsers()

        this.mentionUsers = result.data.users
    }

    private async loadTags() {
        const result = await getTags()

        this.chatTags = result.data
    }

    private processNoteMentions(text) {
        const users = new Map<string, string>()
        for (const user of this.mentionUsers) {
            if (!user.name) {
                if (!user.email) {
                    continue
                }
                user.name = user.email
            }
            if (user.name.trim() !== '') {
                users.set(user.name, user.email)
            }
        }
        users.forEach((email, name) => {
            const replace = `@${name}`
            const re = new RegExp(replace, 'g')
            text = text.replace(re, `[@${name}](user:${email})`)
        })

        return text
    }

    private onMentionAdded(name) {
        this.mentionedUsers.push(name)
    }

    private onMentionDeleted(name) {
        this.mentionedUsers.splice(this.mentionedUsers.indexOf(name), 1)
    }
}
</script>

<style scoped lang="less">
@import '../../../styles/button.less';
@import '../../../styles/palette.less';
@import '../../../styles/fade.less';

.message-container {
    background-color: @greyLight;
    // avoid button overlap the content
    padding: 14px 14px 100px;
    height: calc(100% - 80px);
    overflow-y: scroll;
}

.msgs {
    flex: 1;
}

.footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.buttons {
    text-align: center;
    min-height: 32px;
    position: relative;
    top: 32px;

    .btn {
        background-color: @blue;
        color: @white;
        display: inline-block;
        line-height: 1.143;
        margin: 0 10px;
        padding: 12px 24px;
        border-radius: 4px;
        text-transform: uppercase;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        position: relative;
        font-size: 14px;
    }
}

.close {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    margin-right: 5px;
    color: @midGreyBlue;
    font-size: 12px;

    > span {
        cursor: pointer;
    }

    .cross {
        font-size: 20px;
        position: relative;
        top: 3px;
    }
}

.note-container {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(46, 91, 255, 0.07);
    padding: 15px 15px 30px;
    flex: 1;
    background-color: @yellowMid;
    border: 1px solid @yellow10;

    .top-actions {
        text-align: right;

        .mark-urgent-action {
            display: inline-block;
            padding: 2px 10px;
        }
    }
}

.actions {
    text-align: right;

    .btn {
        position: relative;
        top: 20px;
        padding: 8px 12px;
    }
}

.send-btn {
    border: none;
    font-size: 18px;
    font-weight: 700;
    background: transparent;

    .spinner {
        display: inline-block;
    }

    &.disabled {
        color: #6787ff;
    }
}

.add-btn {
    border-radius: 8px;
    padding: 5px 30px;
}
</style>

<style lang="less">
// This is to overwrite the `Internal Notes` edit area style. The custom-class is 'mobile-note'.
.chat-input-container .chat-input.mobile-note .ql-editor {
    min-height: 160px;
    font-size: 18px;
}
</style>
