import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { DeviceInfo } from '@capacitor/device'
import { getPhoto } from '@/mobile/src/utils/capacitor'
import requestPushNotificationPermission from '@/mobile/src/utils/pushnotification'
import { AppInfo } from '@capacitor/app'

// store for device related state

export interface AskNicelyAppRequirement {
    version: string
    build: string
    operatingsystem: string
}

export interface DeviceRequirement {
    ios: AskNicelyAppRequirement
    android: AskNicelyAppRequirement
}

export interface DeviceState {
    bottomTabsHidden: boolean
    deviceInfo: DeviceInfo & AppInfo
    pushPermission: 'granted' | 'denied' | 'prompt' | 'unknown'
    hasNetworkConnection: boolean
    deviceRequirement: DeviceRequirement
    pushNotificationToken: string
    navBarPosition: 'horizontal' | 'vertical'
}

export const state: DeviceState = {
    bottomTabsHidden: false,
    deviceInfo: {
        id: 'unknown',
        name: 'AskNicely',
        model: 'unknown',
        platform: 'web',
        version: 'unknown',
        build: 'unknown',
        operatingSystem: 'unknown',
        osVersion: 'unknown',
        manufacturer: 'unknown',
        isVirtual: true,
        webViewVersion: 'unknown',
    },
    deviceRequirement: {
        ios: {
            version: '',
            build: '',
            operatingsystem: '',
        },
        android: {
            version: '',
            build: '',
            operatingsystem: '',
        },
    },
    pushPermission: 'unknown',
    hasNetworkConnection: true,
    pushNotificationToken: '',
    navBarPosition: 'horizontal',
}

const getters: GetterTree<DeviceState, any> = {
    bottomTabsHidden({ bottomTabsHidden }) {
        return bottomTabsHidden
    },

    deviceInfo({ deviceInfo }) {
        return deviceInfo
    },

    pushPermission({ pushPermission }) {
        return pushPermission
    },

    hasNetworkConnection({ hasNetworkConnection }) {
        return hasNetworkConnection
    },

    deviceRequirement({ deviceRequirement }) {
        return deviceRequirement
    },

    pushNotificationToken({ pushNotificationToken }) {
        return pushNotificationToken
    },

    navBarPosition({ navBarPosition }) {
        return navBarPosition
    },
}

const actions: ActionTree<DeviceState, any> = {
    hideBottomTabs({ commit }) {
        commit('setBottomTabsHidden', true)
    },

    showBottomTabs({ commit }) {
        commit('setBottomTabsHidden', false)
    },

    async getPhoto() {
        return await getPhoto()
    },

    async requestPushNotificationPermission() {
        return await requestPushNotificationPermission()
    },
}

const mutations: MutationTree<DeviceState> = {
    setBottomTabsHidden(state, hide) {
        state.bottomTabsHidden = hide
    },

    setDeviceInfo(state, deviceInfo) {
        state.deviceInfo = deviceInfo
    },

    setPushPermission(state, permission: 'granted' | 'denied' | 'prompt') {
        state.pushPermission = permission
    },

    setNetworkStatus(state, status) {
        state.hasNetworkConnection = status
    },

    setDeviceRequirement(state, requirements) {
        state.deviceRequirement = requirements
    },

    setPushNotificationToken(state, pushNotificationToken) {
        state.pushNotificationToken = pushNotificationToken
    },

    setNavBarPosition(state, position: 'horizontal' | 'vertical') {
        state.navBarPosition = position
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
